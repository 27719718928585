import React, { useMemo, useState } from "react";  
import { TextFieldProps } from "./TextField";
import { SelectSearchProps } from "../UI/SelectSearch";
import FormSelectSearch from "./FormSelectSearch";
import { SelectOptionItem } from "@tixlabs/types";
import { IPaymentMethod } from "@tixlabs/grpc-client/web-partner";
import { useBooking } from "@web-booker/hooks/apps";

export const FormPaymentMethod = ({
    inputProps = {},
    displayCode = true,
    displayName = true,
    paymentMethodList,
    ...rest
} : {
    displayCode?: boolean,
    displayName?: boolean,
    paymentMethodList: {value: string, label: string}[],
} & TextFieldProps & {
    inputProps?: Pick<
      SelectSearchProps,
      | 'className'
      | 'disabled'
      | 'inputSize'
      | 'inputSearchClassName'
      | 'optionSelectSearchClassName'
      | 'optionGroupSelectSearchClassName'
      | 'isOnlyValue'
      | 'isRoot'
      | 'renderLabel'
      | 'displayValue'
      | 'compareFunc'
    >;
}) => {

    
    return(
        <FormSelectSearch 
            {...rest}
            inputProps={{isOnlyValue: true}}
            selectOptions={paymentMethodList}
        />
    )
}