import {
  AirPlaneIcon,
  BarChartFillIcon,
  BookingIcon,
  FlightFillIcon,
  HotelIcon,
  OrdersIcon,
  TicketTourIcon,
} from '@icon';
import { MenuHeader } from '@web-booker/components/Header';
import Logo from '@web-booker/components/Logo';
import { useUserStore } from '@web-booker/hooks/stores';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import { syncWalletBallance } from '@web-booker/stores/reducers/user';
import { Layout } from 'antd';
import { useEffect, useRef } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';

import { resetBooking } from '@web-booker/stores/reducers/booking';
import { resetSearchFlight } from '@web-booker/stores/reducers/searchFlight';
import cn from 'classnames';
import { EmbedMessagingScript } from '@web-booker/containers';

const { Header, Content, Sider } = Layout;

const items = [
  // {
  //   key: 'home',
  //   label: 'Trang chủ',
  //   icon: <Home5LineIcon className='shrink-0 w-6 h-6' />,
  // },
  {
    key: 'booking-flight',
    label: 'Đặt chuyến bay',
    icon: <AirPlaneIcon className='shrink-0 w-6 h-6' />,
  },
  {
    key: 'order-management',
    label: 'Quản lý đặt chỗ',
    icon: <OrdersIcon className='shrink-0 w-6 h-6' />,
  },
  {
    key: 'transaction-management',
    label: 'Quản lý giao dịch',
    icon: <BookingIcon className='shrink-0 w-6 h-6' />,
  },
  {
    key: 'dashboard',
    label: 'Thống kê',
    icon: <BarChartFillIcon className='shrink-0 w-6 h-6' />,
  },
  // {
  //   key: 'ticket-management',
  //   label: 'Quản lý xuất vé',
  //   icon: <TicketIcon className='shrink-0 w-6 h-6' />,
  // },
  // {
  //   key: 'management',
  //   label: 'QUẢN LÝ',
  //   icon: null,
  //   type: 'group',
  //   children: [
  //     {
  //       key: 'agency',
  //       label: 'Thông tin đại lý',
  //       icon: <InformationLineIcon className='shrink-0 w-6 h-6' />,
  //     },
  //     {
  //       key: 'user',
  //       label: 'Quản lý người dùng',
  //       icon: <GroupLineIcon className='shrink-0 w-6 h-6' />,
  //     },
  //   ],
  // },
  // {
  //   key: 'finance',
  //   label: 'TÀI CHÍNH',
  //   icon: null,
  //   type: 'group',
  //   children: [
  //     {
  //       key: 'service-fee',
  //       label: 'Cài đặt phí dịch vụ',
  //       icon: <ListSettingsFillIcon className='shrink-0 w-6 h-6' />,
  //     },
  //     {
  //       key: 'payment-history',
  //       label: 'Lịch sử thanh toán',
  //       icon: <HistoryFillIcon className='shrink-0 w-6 h-6' />,
  //     },
  //   ],
  // },
  // {
  //   key: 'statistical',
  //   label: 'THỐNG KÊ',
  //   icon: null,
  //   type: 'group',
  //   children: [
  //     {
  //       key: 'search-statistical',
  //       label: 'Lượng tìm kiếm',
  //       icon: <SearchLineIcon className='shrink-0 w-6 h-6' />,
  //     },
  //     {
  //       key: 'order-statistical',
  //       label: 'Lượng đặt chỗ',
  //       icon: <OrdersIcon className='shrink-0 w-6 h-6' />,
  //     },
  //     {
  //       key: 'issue-statistical',
  //       label: 'Lượng xuất vé',
  //       icon: <TicketIcon className='shrink-0 w-6 h-6' />,
  //     },
  //   ],
  // },
  // {
  //   key: 'notification',
  //   label: 'THÔNG BÁO',
  //   icon: null,
  //   type: 'group',
  //   children: [
  //     {
  //       key: 'email-structure',
  //       label: 'Cấu trúc email',
  //       icon: <MailLineIcon className='shrink-0 w-6 h-6' />,
  //     },
  //     {
  //       key: 'notifcation-structure',
  //       label: 'Cấu trúc thông báo',
  //       icon: <Notification3FillIcon className='shrink-0 w-6 h-6' />,
  //     },
  //     {
  //       key: 'ticket-structure',
  //       label: 'Cấu trúc mặt vé điện tử',
  //       icon: <TicketIcon className='shrink-0 w-6 h-6' />,
  //     },
  //   ],
  // },
];

const itemsHeader = [
  {
    key: 'booking-flight',
    label: 'Vé máy bay',
    icon: <FlightFillIcon className='shrink-0 w-6 h-6' />,
  },
  {
    key: 'hotel',
    label: 'Khách sạn',
    icon: <HotelIcon className='shrink-0 w-6 h-6' />,
  },
  {
    key: 'ticket-tour',
    label: 'Vé tham quan',
    icon: <TicketTourIcon className='shrink-0 w-6 h-6' />,
  },
  // {
  //   key: 'booking-flight',
  //   label: 'Khách sạn',
  //   icon: <AirPlaneIcon className='shrink-0 w-6 h-6' />,
  // },
  // {
  //   key: 'order-management',
  //   label: 'Tour du lịch',
  //   icon: <OrdersIcon className='shrink-0 w-6 h-6' />,
  // },
  // {
  //   key: 'transaction-management',
  //   label: 'Sim du lịch',
  //   icon: <BookingIcon className='shrink-0 w-6 h-6' />,
  // },
];

export const DashboardLayout = () => {
  const dispatch = useAppDispatch();
  const topContentRef = useRef<HTMLDivElement>(null);
  const { userData, wallet } = useUserStore();
  const { isRebook } = useAppSelector((state) => state.searchFlight);
  const { pathname } = useLocation();

  useEffect(() => {
    topContentRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
    dispatch(syncWalletBallance());
  }, [pathname]);

  return (
    <Layout
      style={{
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}>
      <Header>
        <div className='flex justify-between h-full items-center text-base'>
          <div className='flex space-x-5 items-center'>
            <div>
              <Logo className='h-[32px]' type='logo-white' />
            </div>
            {/* <div className='flex gap-5'>
              {itemsHeader.map((item, index) => {
                return (
                  <NavLink
                    key={index}
                    className={({ isActive }) =>
                      cn(
                        'relative group text-theme-white-variant-3 hover:text-white',
                        {
                          'is-active text-white': isActive,
                        }
                      )
                    }
                    to={item.key}>
                    <div className='flex items-center gap-3 p-3 group-[.is-active]:text-white group-hover:text-white group-[.is-active]:bg-[#ffffff1a] group-hover:bg-[#ffffff1a] rounded-md'>
                      <div>{item.icon}</div>
                      <span className='font-semibold leading-[18px]'>
                        {item.label}
                      </span>
                    </div>
                  </NavLink>
                );
              })}
            </div> */}
            {/* <Button
              type='text'
              icon={<MenuLineIcon />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                color: 'white',
                fontSize: '16px',
              }}
            /> */}
          </div>
          <MenuHeader
            name={userData?.name || ''}
            shopID={userData?.partnerShopId || ''}
            wallet={wallet}
          />
        </div>
      </Header>
      <div className='flex shadow-md bg-theme-white px-3'>
        <div className='flex gap-5'>
          {items.map((item, index) => {
            return (
              <NavLink
                key={index}
                className={({ isActive }) =>
                  cn('relative group hover:text-primary-5', {
                    'is-active text-primary-5': isActive,
                  })
                }
                to={item.key}
                onClick={() => {
                  dispatch(resetSearchFlight());
                  dispatch(resetBooking({}));
                }}>
                <div
                  className={
                    'absolute bottom-0 w-full h-0.5 duration-200 group-[.is-active]:bg-primary-4 group-hover:bg-primary-4'
                  }></div>
                <div className='flex items-center gap-3 p-3'>
                  <div>{item.icon}</div>
                  <span>{item.label}</span>
                </div>
              </NavLink>
            );
          })}
        </div>
      </div>
      <Layout>
        {/* <Sider
          breakpoint='lg'
          onBreakpoint={(isBroken) => {
            if (isBroken) {
              setCollapsed(true);
            }
          }}
          collapsed={collapsed}
          style={{ padding: '20px 10px' }}
          collapsedWidth={80}
          width={270}>
          <Menu
            mode='inline'
            defaultSelectedKeys={['home']}
            defaultOpenKeys={['home']}
            items={items}
            selectedKeys={pathname.split('/')}
            onClick={(info) => {
              navigate(info.keyPath.join('/'));
            }}
          />
        </Sider> */}

        <Content
          id='right-content'
          className='p-2.5 overflow-x-hidden overflow-y-auto'
          rootClassName='bg-[#F0F2F5]'>
          <div ref={topContentRef}></div>
          <Outlet />
          <EmbedMessagingScript />
        </Content>
      </Layout>
    </Layout>
  );
};
export default DashboardLayout;
