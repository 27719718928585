/**
 * @fileoverview gRPC-Web generated client stub for airplane.web
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web/flight.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var airplane_base_pb = require('../../airplane/base_pb.js')

var airplane_flight_pb = require('../../airplane/flight_pb.js')

var airplane_domestic_flight_pb = require('../../airplane/domestic_flight_pb.js')

var airplane_hub_pb = require('../../airplane/hub_pb.js')

var airplane_seat_pb = require('../../airplane/seat_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web = require('./flight_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web.FlightServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web.FlightServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.SearchFlightReq,
 *   !proto.airplane.web.SearchFlightRes>}
 */
const methodDescriptor_FlightService_SearchFlight = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/SearchFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.SearchFlightReq,
  proto.airplane.web.SearchFlightRes,
  /**
   * @param {!proto.airplane.web.SearchFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.SearchFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.SearchFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.SearchFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.SearchFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.searchFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/SearchFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlight,
      callback);
};


/**
 * @param {!proto.airplane.web.SearchFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.SearchFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.searchFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/SearchFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.SearchAirportsReq,
 *   !proto.airplane.web.SearchAirportsRes>}
 */
const methodDescriptor_FlightService_SearchAirports = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/SearchAirports',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.SearchAirportsReq,
  proto.airplane.web.SearchAirportsRes,
  /**
   * @param {!proto.airplane.web.SearchAirportsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.SearchAirportsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.SearchAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.SearchAirportsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.SearchAirportsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.searchAirports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/SearchAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirports,
      callback);
};


/**
 * @param {!proto.airplane.web.SearchAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.SearchAirportsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.searchAirports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/SearchAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchAirports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.GetAirportsReq,
 *   !proto.airplane.web.GetAirportsRes>}
 */
const methodDescriptor_FlightService_GetAirports = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/GetAirports',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.GetAirportsReq,
  proto.airplane.web.GetAirportsRes,
  /**
   * @param {!proto.airplane.web.GetAirportsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.GetAirportsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.GetAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.GetAirportsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.GetAirportsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.getAirports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/GetAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetAirports,
      callback);
};


/**
 * @param {!proto.airplane.web.GetAirportsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.GetAirportsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.getAirports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/GetAirports',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetAirports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.FareCheckReq,
 *   !proto.airplane.web.FareCheckRes>}
 */
const methodDescriptor_FlightService_FareCheck = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/FareCheck',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.FareCheckReq,
  proto.airplane.web.FareCheckRes,
  /**
   * @param {!proto.airplane.web.FareCheckReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.FareCheckRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.FareCheckReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.FareCheckRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.FareCheckRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.fareCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/FareCheck',
      request,
      metadata || {},
      methodDescriptor_FlightService_FareCheck,
      callback);
};


/**
 * @param {!proto.airplane.web.FareCheckReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.FareCheckRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.fareCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/FareCheck',
      request,
      metadata || {},
      methodDescriptor_FlightService_FareCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.SearchMinFareReq,
 *   !proto.airplane.web.SearchMinFareRes>}
 */
const methodDescriptor_FlightService_SearchMinFare = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/SearchMinFare',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.SearchMinFareReq,
  proto.airplane.web.SearchMinFareRes,
  /**
   * @param {!proto.airplane.web.SearchMinFareReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.SearchMinFareRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.SearchMinFareReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.SearchMinFareRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.SearchMinFareRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.searchMinFare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/SearchMinFare',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchMinFare,
      callback);
};


/**
 * @param {!proto.airplane.web.SearchMinFareReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.SearchMinFareRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.searchMinFare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/SearchMinFare',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchMinFare);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.BookingFlightReq,
 *   !proto.airplane.web.BookingFlightRes>}
 */
const methodDescriptor_FlightService_BookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/BookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.BookingFlightReq,
  proto.airplane.web.BookingFlightRes,
  /**
   * @param {!proto.airplane.web.BookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.BookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.BookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.BookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.BookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.bookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/BookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_BookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web.BookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.BookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.bookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/BookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_BookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.RetrieveBookingFlightReq,
 *   !proto.airplane.web.RetrieveBookingFlightRes>}
 */
const methodDescriptor_FlightService_RetrieveBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/RetrieveBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.RetrieveBookingFlightReq,
  proto.airplane.web.RetrieveBookingFlightRes,
  /**
   * @param {!proto.airplane.web.RetrieveBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.RetrieveBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.RetrieveBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.RetrieveBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.RetrieveBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.retrieveBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/RetrieveBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web.RetrieveBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.RetrieveBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.retrieveBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/RetrieveBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_RetrieveBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.CalculationFlightFeeReq,
 *   !proto.airplane.web.CalculationFlightFeeRes>}
 */
const methodDescriptor_FlightService_CalculationFlightFee = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/CalculationFlightFee',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.CalculationFlightFeeReq,
  proto.airplane.web.CalculationFlightFeeRes,
  /**
   * @param {!proto.airplane.web.CalculationFlightFeeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.CalculationFlightFeeRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.CalculationFlightFeeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.CalculationFlightFeeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.CalculationFlightFeeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.calculationFlightFee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/CalculationFlightFee',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalculationFlightFee,
      callback);
};


/**
 * @param {!proto.airplane.web.CalculationFlightFeeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.CalculationFlightFeeRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.calculationFlightFee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/CalculationFlightFee',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalculationFlightFee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.ListBookingFlightReq,
 *   !proto.airplane.web.ListBookingFlightRes>}
 */
const methodDescriptor_FlightService_ListBookingFlight = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/ListBookingFlight',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.ListBookingFlightReq,
  proto.airplane.web.ListBookingFlightRes,
  /**
   * @param {!proto.airplane.web.ListBookingFlightReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.ListBookingFlightRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.ListBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.ListBookingFlightRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.ListBookingFlightRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.listBookingFlight =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/ListBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlight,
      callback);
};


/**
 * @param {!proto.airplane.web.ListBookingFlightReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.ListBookingFlightRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.listBookingFlight =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/ListBookingFlight',
      request,
      metadata || {},
      methodDescriptor_FlightService_ListBookingFlight);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.GetSeatMapReq,
 *   !proto.airplane.web.GetSeatMapRes>}
 */
const methodDescriptor_FlightService_GetSeatMap = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/GetSeatMap',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.GetSeatMapReq,
  proto.airplane.web.GetSeatMapRes,
  /**
   * @param {!proto.airplane.web.GetSeatMapReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.GetSeatMapRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.GetSeatMapReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.GetSeatMapRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.GetSeatMapRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.getSeatMap =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/GetSeatMap',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetSeatMap,
      callback);
};


/**
 * @param {!proto.airplane.web.GetSeatMapReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.GetSeatMapRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.getSeatMap =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/GetSeatMap',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetSeatMap);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.GetBaggageOptionsReq,
 *   !proto.airplane.web.GetBaggageOptionsRes>}
 */
const methodDescriptor_FlightService_GetBaggageOptions = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/GetBaggageOptions',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.GetBaggageOptionsReq,
  proto.airplane.web.GetBaggageOptionsRes,
  /**
   * @param {!proto.airplane.web.GetBaggageOptionsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.GetBaggageOptionsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.GetBaggageOptionsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.GetBaggageOptionsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.GetBaggageOptionsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.getBaggageOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/GetBaggageOptions',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetBaggageOptions,
      callback);
};


/**
 * @param {!proto.airplane.web.GetBaggageOptionsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.GetBaggageOptionsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.getBaggageOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/GetBaggageOptions',
      request,
      metadata || {},
      methodDescriptor_FlightService_GetBaggageOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.IssueTicketReq,
 *   !proto.airplane.web.IssueTicketRes>}
 */
const methodDescriptor_FlightService_IssueTicket = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/IssueTicket',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.IssueTicketReq,
  proto.airplane.web.IssueTicketRes,
  /**
   * @param {!proto.airplane.web.IssueTicketReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.IssueTicketRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.IssueTicketReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.IssueTicketRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.IssueTicketRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.issueTicket =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/IssueTicket',
      request,
      metadata || {},
      methodDescriptor_FlightService_IssueTicket,
      callback);
};


/**
 * @param {!proto.airplane.web.IssueTicketReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.IssueTicketRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.issueTicket =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/IssueTicket',
      request,
      metadata || {},
      methodDescriptor_FlightService_IssueTicket);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.MarkBookingAsFailedAndCancelReq,
 *   !proto.airplane.web.MarkBookingAsFailedAndCancelRes>}
 */
const methodDescriptor_FlightService_MarkBookingAsFailedAndCancel = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/MarkBookingAsFailedAndCancel',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.MarkBookingAsFailedAndCancelReq,
  proto.airplane.web.MarkBookingAsFailedAndCancelRes,
  /**
   * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.MarkBookingAsFailedAndCancelRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.MarkBookingAsFailedAndCancelRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.MarkBookingAsFailedAndCancelRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.markBookingAsFailedAndCancel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/MarkBookingAsFailedAndCancel',
      request,
      metadata || {},
      methodDescriptor_FlightService_MarkBookingAsFailedAndCancel,
      callback);
};


/**
 * @param {!proto.airplane.web.MarkBookingAsFailedAndCancelReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.MarkBookingAsFailedAndCancelRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.markBookingAsFailedAndCancel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/MarkBookingAsFailedAndCancel',
      request,
      metadata || {},
      methodDescriptor_FlightService_MarkBookingAsFailedAndCancel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.BookingFlightV2Req,
 *   !proto.airplane.web.BookingFlightV2Res>}
 */
const methodDescriptor_FlightService_BookingFlightV2 = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/BookingFlightV2',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.BookingFlightV2Req,
  proto.airplane.web.BookingFlightV2Res,
  /**
   * @param {!proto.airplane.web.BookingFlightV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.BookingFlightV2Res.deserializeBinary
);


/**
 * @param {!proto.airplane.web.BookingFlightV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.BookingFlightV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.BookingFlightV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.bookingFlightV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/BookingFlightV2',
      request,
      metadata || {},
      methodDescriptor_FlightService_BookingFlightV2,
      callback);
};


/**
 * @param {!proto.airplane.web.BookingFlightV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.BookingFlightV2Res>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.bookingFlightV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/BookingFlightV2',
      request,
      metadata || {},
      methodDescriptor_FlightService_BookingFlightV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.SearchFlightDomesticV2Req,
 *   !proto.airplane.web.SearchFlightDomesticV2Res>}
 */
const methodDescriptor_FlightService_SearchFlightDomesticV2 = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/SearchFlightDomesticV2',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.SearchFlightDomesticV2Req,
  proto.airplane.web.SearchFlightDomesticV2Res,
  /**
   * @param {!proto.airplane.web.SearchFlightDomesticV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.SearchFlightDomesticV2Res.deserializeBinary
);


/**
 * @param {!proto.airplane.web.SearchFlightDomesticV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.SearchFlightDomesticV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.SearchFlightDomesticV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.searchFlightDomesticV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/SearchFlightDomesticV2',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlightDomesticV2,
      callback);
};


/**
 * @param {!proto.airplane.web.SearchFlightDomesticV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.SearchFlightDomesticV2Res>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.searchFlightDomesticV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/SearchFlightDomesticV2',
      request,
      metadata || {},
      methodDescriptor_FlightService_SearchFlightDomesticV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.CalculationFlightFeeV2Req,
 *   !proto.airplane.web.CalculationFlightFeeRes>}
 */
const methodDescriptor_FlightService_CalculationFlightFeeV2 = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/CalculationFlightFeeV2',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.CalculationFlightFeeV2Req,
  proto.airplane.web.CalculationFlightFeeRes,
  /**
   * @param {!proto.airplane.web.CalculationFlightFeeV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.CalculationFlightFeeRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.CalculationFlightFeeV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.CalculationFlightFeeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.CalculationFlightFeeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.calculationFlightFeeV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/CalculationFlightFeeV2',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalculationFlightFeeV2,
      callback);
};


/**
 * @param {!proto.airplane.web.CalculationFlightFeeV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.CalculationFlightFeeRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.calculationFlightFeeV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/CalculationFlightFeeV2',
      request,
      metadata || {},
      methodDescriptor_FlightService_CalculationFlightFeeV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web.FareCheckV2Req,
 *   !proto.airplane.web.FareCheckRes>}
 */
const methodDescriptor_FlightService_FareCheckV2 = new grpc.web.MethodDescriptor(
  '/airplane.web.FlightService/FareCheckV2',
  grpc.web.MethodType.UNARY,
  proto.airplane.web.FareCheckV2Req,
  proto.airplane.web.FareCheckRes,
  /**
   * @param {!proto.airplane.web.FareCheckV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web.FareCheckRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web.FareCheckV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web.FareCheckRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web.FareCheckRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web.FlightServiceClient.prototype.fareCheckV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web.FlightService/FareCheckV2',
      request,
      metadata || {},
      methodDescriptor_FlightService_FareCheckV2,
      callback);
};


/**
 * @param {!proto.airplane.web.FareCheckV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web.FareCheckRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web.FlightServicePromiseClient.prototype.fareCheckV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web.FlightService/FareCheckV2',
      request,
      metadata || {},
      methodDescriptor_FlightService_FareCheckV2);
};


module.exports = proto.airplane.web;

