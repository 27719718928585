import useBooking from '@web-booker/hooks/apps/useBooking';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmContactInfoCtn } from './ConfirmContactInfo';

import { Card, GroupFillIcon } from '@common-ui';
import { InvoiceInfoView } from '@web-booker/components/OrderManagement';
import { ConfirmPassengerInfoCtn } from './ConfirmPassengerInfo';
import { ConfirmChooseSeatCtn } from './ConfirmChooseSeat';
import ConfirmChooseBaggageCtn from './ConfirmChooseBaggage/ConfirmChooseBaggageCtn';

type Props = {
  //
};

export const ConfirmBookingInfoCtn = (props: Props) => {
  const navigate = useNavigate();
  const {
    flightDataList,
    flightDataDomesticList,
    flightDataDomesticRebookList,
    isInvoiceInfo,
    invoicingInformation,
    isOnlyDomesticBooking,
    isRebook,
  } = useBooking();
  useEffect(() => {
    if (isRebook) {
      if (!flightDataDomesticRebookList.length) {
        navigate('../search');
      }
      return;
    }
    if (isOnlyDomesticBooking) {
      if (!flightDataDomesticList.length) {
        navigate('../search');
      }
      return;
    } else {
      if (!flightDataList.length) {
        navigate('../search');
      }
      return;
    }
  }, []);

  return (
    <div className='flex flex-col space-y-5'>
      <ConfirmPassengerInfoCtn />
      {/* <ConfirmChooseBaggageCtn /> */}
      {/* <ConfirmChooseSeatCtn /> */}
      <ConfirmContactInfoCtn />
      {isInvoiceInfo && (
        <Card
          title='Thông tin xuất hóa đơn'
          icon={<GroupFillIcon />}
          classNameChildren='px-3 py-5'
          isCollapse>
          <InvoiceInfoView data={invoicingInformation} />
        </Card>
      )}
    </div>
  );
};

export default ConfirmBookingInfoCtn;
