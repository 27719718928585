import cn from 'classnames';
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

const ButtonTheme = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  NEUTRAL: 'neutral',
  GREEN: 'green',
  RED: 'red',
  WHITE: 'white',
  BLACK: 'black',
} as const;

type TButtonTheme = typeof ButtonTheme[keyof typeof ButtonTheme];

const ButtonSize = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const;

export type TButtonSize = typeof ButtonSize[keyof typeof ButtonSize];

const ButtonRound = {
  BASE: 'base',
  NONE: 'none',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  FULL: 'full',
} as const;

type TButtonRound = typeof ButtonRound[keyof typeof ButtonRound];

const ButtonVariant = {
  SOLID: 'solid',
  OUTLINE: 'outline',
  GHOST: 'ghost',
} as const;

type TButtonVariant = typeof ButtonVariant[keyof typeof ButtonVariant];

const buttonStyleDefault = {
  [ButtonVariant.SOLID]: 'border-none',
  [ButtonVariant.OUTLINE]: 'border border-solid ',
  [ButtonVariant.GHOST]: 'border-none',
};

const buttonStyleByTheme = {
  [ButtonVariant.SOLID]: {
    [ButtonTheme.PRIMARY]:
      'bg-primary text-theme-white hover:bg-primary-variant-1 active:bg-primary-variant-2',
    [ButtonTheme.SECONDARY]:
      'bg-secondary text-theme-white hover:bg-secondary-variant-1 active:bg-secondary-variant-2',
    [ButtonTheme.WHITE]:
      'bg-theme-white text-theme-black hover:bg-theme-white-variant-1 active:bg-theme-white-variant-2',
    [ButtonTheme.BLACK]:
      'bg-theme-black text-theme-white hover:bg-theme-black-variant-1 active:bg-theme-black-variant-2',
    [ButtonTheme.NEUTRAL]:
      'bg-neutral-7 text-theme-white hover:bg-neutral-8 active:bg-neutral-9',
    [ButtonTheme.GREEN]:
      'bg-green-6 text-theme-white hover:bg-green-7 active:bg-green-8',
    [ButtonTheme.RED]:
      'bg-red-6 text-theme-white hover:bg-red-7 active:bg-red-7',
  },
  [ButtonVariant.OUTLINE]: {
    [ButtonTheme.PRIMARY]:
      'border-primary text-primary hover:border-primary-variant-1 active:border-primary-variant-2',
    [ButtonTheme.SECONDARY]:
      'border-secondary text-secondary hover:border-secondary-variant-1 active:border-secondary-variant-2',
    [ButtonTheme.WHITE]:
      'border-theme-white text-theme-white hover:border-theme-white-variant-1 active:border-theme-white-variant-2',
    [ButtonTheme.BLACK]:
      'border-theme-black text-theme-black hover:border-theme-black-variant-1 active:border-theme-black-variant-2',
    [ButtonTheme.NEUTRAL]:
      'border-neutral-7 text-theme-black hover:border-neutral-8 active:border-neutral-9',
    [ButtonTheme.GREEN]:
      'border-green-6 text-theme-black hover:border-green-7 active:border-green-8',
    [ButtonTheme.RED]:
      'border-red-6 text-red-6 hover:border-red-7 active:border-red-8',
  },
  [ButtonVariant.GHOST]: {
    [ButtonTheme.PRIMARY]:
      'text-primary bg-primary-1 hover:bg-primary-2 active:bg-primary/20',
    [ButtonTheme.SECONDARY]:
      'text-secondary bg-secondary-1 hover:bg-secondary-2 active:bg-secondary/20',
    [ButtonTheme.WHITE]:
      'text-theme-black hover:bg-theme-white/10 active:bg-theme-white/20',
    [ButtonTheme.BLACK]:
      'text-theme-black hover:bg-theme-black/10 active:bg-theme-black/20',
    [ButtonTheme.NEUTRAL]:
      'text-neutral-7 hover:bg-neutral-8 active:bg-neutral-9',
    [ButtonTheme.GREEN]: 'text-green-6 hover:bg-green-7 active:bg-green-8',
    [ButtonTheme.RED]: 'text-red-6 bg-red-1 hover:bg-red-2 active:bg-red/20',
  },
};

const buttonStyleActiveByTheme = {
  [ButtonVariant.SOLID]: {
    [ButtonTheme.PRIMARY]: 'bg-primary-variant-2',
    [ButtonTheme.SECONDARY]: 'bg-secondary-variant-2',
    [ButtonTheme.WHITE]: 'bg-theme-white-variant-2',
    [ButtonTheme.BLACK]: 'bg-theme-black-variant-2',
    [ButtonTheme.NEUTRAL]: 'bg-neutral-9',
    [ButtonTheme.GREEN]: 'bg-green-8',
    [ButtonTheme.RED]: 'bg-red-7',
  },
  [ButtonVariant.OUTLINE]: {
    [ButtonTheme.PRIMARY]: 'border-primary-variant-2',
    [ButtonTheme.SECONDARY]: 'border-secondary-variant-2',
    [ButtonTheme.WHITE]: 'border-theme-white-variant-2',
    [ButtonTheme.BLACK]: 'border-theme-black-variant-2',
    [ButtonTheme.NEUTRAL]: 'border-neutral-9',
    [ButtonTheme.GREEN]: 'border-green-8',
    [ButtonTheme.RED]: 'border-red-4',
  },
  [ButtonVariant.GHOST]: {
    [ButtonTheme.PRIMARY]: 'bg-primary/20',
    [ButtonTheme.SECONDARY]: 'bg-secondary/20',
    [ButtonTheme.WHITE]: 'bg-theme-white/20',
    [ButtonTheme.BLACK]: 'bg-theme-black/20',
    [ButtonTheme.NEUTRAL]: 'bg-neutral-9',
    [ButtonTheme.GREEN]: 'bg-green-8',
    [ButtonTheme.RED]: 'border-red-4',
  },
};

const buttonSize = {
  [ButtonSize.SM]: 'py-2.5 px-2 text-sm leading-5 font-semibold',
  [ButtonSize.MD]: 'py-2.5 px-3 text-md font-semibold',
  [ButtonSize.LG]: 'py-4 px-4 text-md font-semibold',
};

type Props = {
  variant?: TButtonVariant;
  size?: TButtonSize;
  rounded?: TButtonRound;
  isDisabled?: boolean;
  isActive?: boolean;
  fullWidth?: boolean;
  uppercase?: boolean;
  className?: string;
  arrowClassName?: string;
  prefixIcon?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  theme?: TButtonTheme;
};

export type ButtonType = Props &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button = ({
  children,
  className,
  arrowClassName,
  variant = ButtonVariant.SOLID,
  fullWidth = false,
  uppercase = false,
  size = ButtonSize.SM,
  rounded = ButtonRound.BASE,
  isDisabled,
  isActive,
  theme = ButtonTheme.PRIMARY,
  prefixIcon,
  suffixIcon,
  type = 'button',
  ...rest
}: ButtonType) => {
  return (
    <button
      disabled={isDisabled}
      className={cn(
        `rounded-${rounded} text-center text-base duration-150 inline-flex justify-center items-center`,
        'disabled:pointer-events-none disabled:border-transparent disabled:bg-theme-white-variant-3 disabled:bg-none disabled:text-theme-black/30 disabled:hover:shadow-none',
        {
          'w-full': fullWidth,
          uppercase: uppercase,
          [buttonStyleDefault[variant]]: true,
          [buttonStyleByTheme[variant][theme]]: true,
          [buttonSize[size]]: true,
          [buttonStyleActiveByTheme[variant][theme]]: isActive,
          [className || '']: !!className,
          'flex items-center space-x-2': suffixIcon || prefixIcon,
          '!max-h-14': variant === ButtonVariant.OUTLINE,
          rounded: rounded === ButtonRound.BASE,
        }
      )}
      type={type}
      {...rest}>
      {prefixIcon}
      {typeof children === 'string' ? (
        <span
          className={cn('text-inherit', {
            'flex-1': suffixIcon,
          })}>
          {children}
        </span>
      ) : (
        children
      )}
      {suffixIcon}
    </button>
  );
};

export default Button;
