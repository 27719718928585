import {
  IBookingFlightData,
  IBookingFlightDomesticData,
  IDomesticFlightInfoRebook,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import { useAppDispatch, useAppSelector } from '@web-booker/stores';
import { IFormBooking, IPassengerForm } from '@web-booker/types/ui/booking';
import { useAppMutation } from '../internals';
import { useState } from 'react';
import { getApiErrorMessages } from '@core/utils';
import { customDomesticFlightInfoRebook, getFlightId } from '@web-booker/utils';
import { updateSelectHistory } from '@web-booker/stores/reducers/searchFlightHistory';
import {
  resetBooking,
  updateBookingInfo,
} from '@web-booker/stores/reducers/booking';
import { resetAdditionalService } from '@web-booker/stores/reducers/additionalService';
import {
  resetSearchFlight,
  submitSearchData,
  updateSelectIndex,
} from '@web-booker/stores/reducers/searchFlight';
import { useNavigate } from 'react-router-dom';
import { EBookingStatus } from '@tixlabs/grpc-client';
import useBooking from './useBooking';
import {
  IBookingFlightContactInfo,
  IBookingFlightPassenger,
  IPassengerQuantity,
} from '@tixlabs/types';
import { CAInfo } from '@api/airplane/web_partner/flight_pb';
import useServiceFee from './useServiceFee';

export const useValidateBooking = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [validateResponse, setValidateResponse] =
    useState<IBookingFlightData>();
  const [validateDomesticResponse, setValidateDomesticResponse] =
    useState<IBookingFlightDomesticData>();
  const {
    domestic,
    international,
    differenceFee,
    isDebtCa
  } = useServiceFee();

  const { searchHistoryList } = useAppSelector(
    (state) => state.searchFlightHistory
  );

  const {
    bookingInfo: {
      flightDataList,
      sessionId,
      keyDomestic,
      flightIdDomestic,
      flightDataDomesticList,
      makeupPrice,
      key,
      flightDataDomesticRebookList,
      flightIdDomesticRebook,
      keyDomesticRebook,
    },
    calculatedFlightFee,
    calculatedFlightFeeRebook,
  } = useAppSelector((state) => state.booking);

  const { isDomesticSearch, filter, itineraryType, rebookIndex } =
    useAppSelector((state) => state.searchFlight);

  const { mutateAsync: validateInfo, isLoading: isLoadingValidate } =
    useAppMutation({
      mutationKey: ['flightApiService', 'bookingFlight'],
      mutationFn: flightApiService?.bookingFlight,
    });
  const {
    mutateAsync: validateInfoDomestic,
    isLoading: isLoadingValidateDomestic,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'bookingFlight'],
    mutationFn: flightApiService?.bookingFlightDomestic,
  });

  const validateBooking = async (
    data: IFormBooking,
    handleSuccess: (data: IBookingFlightData) => void,
    handleError: (errorCode: string) => void
  ) => {
    const passengerListConverted = data.passengersList.map((passenger) => ({
      ...passenger,
      dob: new Date(passenger.dob as Date).getTime(),
      expirationDate:
        passenger.expirationDate && passenger.expirationDate !== null
          ? new Date(passenger.expirationDate).getTime()
          : undefined,
      baggageOptionsList: passenger.baggageOptionsList.filter(
        (b) => Object.keys(b).length !== 0
      ),
    }));
    try {
      const flightId = getFlightId(flightDataList);

      const {
        isSuccess,
        errorCode,
        data: response,
      } = await validateInfo({
        currency: 'VND',
        flightId,
        isPublic: false,
        key: key,
        note: '',
        contactInfo: data.contactInfo,
        manualServiceFee: {
          domestic: domestic,
          international: international,
          differenceFee: differenceFee,
          isDebtCa: isDebtCa
        },
        caInfo: data.caInfo,
        sessionId: sessionId,
        passengersList: passengerListConverted,
        confirmPrice: calculatedFlightFee.totalPrice,
        invoicingInformation: data.isInvoicing
          ? data.invoicingInformation
          : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        makeupPrice,
        domesticFlightPairsList: [],
        isDraftForOverride: false,
      });

      if (isSuccess && response) {
        setValidateResponse(response);
        if (
          response?.fee &&
          response.fee.totalPrice !== calculatedFlightFee.totalPrice
        ) {
          throw new Error('PRICE_CHANGED');
        }
        handleSuccess(response);
        return;
      }
      if (!isSuccess && errorCode) {
        throw new Error(errorCode);
      }
    } catch (error) {
      const msg = getApiErrorMessages(error);
      handleError(msg[0]);
    }
  };
  const validateBookingDomestic = async (
    data: IFormBooking,
    handleSuccess: (data: IBookingFlightDomesticData) => void,
    handleError: (errorCode: string) => void
  ) => {
    const passengerListConverted = data.passengersList.map((passenger) => ({
      ...passenger,
      dob: new Date(passenger.dob as Date).getTime(),
      expirationDate:
        passenger.expirationDate && passenger.expirationDate !== null
          ? new Date(passenger.expirationDate).getTime()
          : undefined,
      baggageOptionsList: passenger.baggageOptionsList.filter(
        (b) => Object.keys(b).length !== 0
      ),
    }));
    try {
      const res = await validateInfoDomestic({
        currency: 'VND',
        flightId: '',
        isPublic: false,
        key: '',
        note: '',
        contactInfo: data.contactInfo,
        caInfo: data.caInfo,
        manualServiceFee: {
          domestic: domestic,
          international: international,
          differenceFee: differenceFee,
          isDebtCa: isDebtCa
        },
        sessionId: '',
        passengersList: passengerListConverted,
        confirmPrice: calculatedFlightFee.totalPrice,
        invoicingInformation: data.isInvoicing
          ? data.invoicingInformation
          : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        makeupPrice,
        domesticFlightPairsList: flightDataDomesticList.map(
          (_, flightDataDomesticIndex) => ({
            flightId: flightIdDomestic[flightDataDomesticIndex],
            key: keyDomestic[flightDataDomesticIndex],
          })
        ),
        // flag for rebook
        isDraftForOverride: false,
      });

      if (res.isSuccess) {
        setValidateDomesticResponse(res);
        if (res.fee && res.fee.totalPrice !== calculatedFlightFee.totalPrice) {
          throw new Error('PRICE_CHANGED');
        }
        handleSuccess(res);
        return;
      }
      if (!res.isSuccess && res.errorCode) {
        throw new Error(res.errorCode);
      }
    } catch (error) {
      const msg = getApiErrorMessages(error);
      handleError(msg[0]);
    }
  };
  const validateBookingDomesticRebook = async (
    data: IFormBooking,
    handleSuccess: (data: IBookingFlightDomesticData) => void,
    handleError: (errorCode: string) => void
  ) => {
    const passengerListConverted = data.passengersList.map((passenger) => ({
      ...passenger,
      dob: new Date(passenger.dob as Date).getTime(),
      expirationDate:
        passenger.expirationDate && passenger.expirationDate !== null
          ? new Date(passenger.expirationDate).getTime()
          : undefined,
      baggageOptionsList: passenger.baggageOptionsList.filter(
        (b) => Object.keys(b).length !== 0
      ),
    }));

    try {
      const res = await validateInfoDomestic({
        currency: 'VND',
        flightId: '',
        isPublic: false,
        key: '',
        note: '',
        contactInfo: data.contactInfo,
        caInfo: data.caInfo,
        manualServiceFee: {
          domestic: domestic,
          international: international,
          differenceFee: differenceFee,
          isDebtCa: isDebtCa
        },
        sessionId: '',
        passengersList: passengerListConverted,
        confirmPrice: calculatedFlightFeeRebook.totalPrice,
        invoicingInformation: data.isInvoicing
          ? data.invoicingInformation
          : undefined,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        makeupPrice,
        domesticFlightPairsList: [
          {
            flightId: flightIdDomesticRebook[rebookIndex],
            key: keyDomesticRebook[rebookIndex],
          },
        ],
        // flag for rebook
        isDraftForOverride: true,
      });

      if (res.isSuccess) {
        setValidateDomesticResponse(res);
        if (
          res.fee &&
          res.fee.totalPrice !== calculatedFlightFeeRebook.totalPrice
        ) {
          throw new Error('PRICE_CHANGED');
        }
        handleSuccess(res);
        return;
      }
      if (!res.isSuccess && res.errorCode) {
        throw new Error(res.errorCode);
      }
    } catch (error) {
      const msg = getApiErrorMessages(error);
      handleError(msg[0]);
    }
  };
  const handleReSearchFlight = () => {
    if (isDomesticSearch) {
      navigate('/booking-flight/search');
      dispatch(resetBooking({ isKeepInfoPax: true }));
      dispatch(resetSearchFlight());
      dispatch(resetAdditionalService());
      return;
    }
    navigate('/booking-flight/search');
    dispatch(resetBooking({ isKeepInfoPax: true }));
    dispatch(resetSearchFlight());
    dispatch(resetAdditionalService());
    dispatch(updateSelectHistory(searchHistoryList[0]));
  };
  const handleReSearchRebookFlight = () => {
    navigate('/booking-flight/search');
    dispatch(updateSelectIndex(rebookIndex));
    dispatch(
      updateBookingInfo({
        flightDataDomesticRebookList: flightDataDomesticRebookList.map(
          (flight, index) => {
            if (index === rebookIndex) {
              return customDomesticFlightInfoRebook(flight);
            }
            return flight;
          }
        ),
      })
    );
  };

  const handleHoldAndIssueReSearch = () => {
    navigate('/booking-flight/search');
    dispatch(resetBooking({}));
    dispatch(resetSearchFlight());
    dispatch(resetAdditionalService());
    isDomesticSearch
      ? dispatch(
          submitSearchData({
            filter,
            itineraryType,
            isDomesticSearch,
          })
        )
      : dispatch(updateSelectHistory(searchHistoryList[0]));
  };

  return {
    isLoadingValidate: isDomesticSearch
      ? isLoadingValidateDomestic
      : isLoadingValidate,
    validateResponse,
    validateDomesticResponse,
    validateBooking,
    validateBookingDomestic,
    validateBookingDomesticRebook,
    handleReSearchFlight,
    handleReSearchRebookFlight,
  };
};

export default useValidateBooking;
