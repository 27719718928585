//Phu
export { ReactComponent as AdultIcon } from '../../assets/icons/adult.svg';
export { ReactComponent as AirPlaneIcon } from '../../assets/icons/airplane.svg';
export { ReactComponent as ArrowDownSLineIcon } from '../../assets/icons/arrow-down-s-line.svg';
export { ReactComponent as BabyIcon } from '../../assets/icons/baby.svg';
export { ReactComponent as BaggageHandIcon } from '../../assets/icons/baggage-hand.svg';
export { ReactComponent as BaggageIcon } from '../../assets/icons/baggage.svg';
export { ReactComponent as BriefCaseFillIcon } from '../../assets/icons/brief-case-fill.svg';
export { ReactComponent as Calendar2LineIcon } from '../../assets/icons/calendar-2-line.svg';
export { ReactComponent as ChildIcon } from '../../assets/icons/child.svg';
export { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
export { ReactComponent as CrossLineIcon } from '../../assets/icons/cross-line.svg';
export { ReactComponent as DeleteBin6Line } from '../../assets/icons/delete-bin-6-line.svg';
export { ReactComponent as DollarIcon } from '../../assets/icons/dollar.svg';
export { ReactComponent as FileExcelLineIcon } from '../../assets/icons/file-excel-line.svg';
export { ReactComponent as FilterAirLineIcon } from '../../assets/icons/filter-airline.svg';
export { ReactComponent as DisplayModeIcon } from '../../assets/icons/filter-display-mode.svg';
export { ReactComponent as FilterFillIcon } from '../../assets/icons/filter-fill.svg';
export { ReactComponent as FlashLightFillIcon } from '../../assets/icons/flashlight-fill.svg';
export { ReactComponent as FlightLandingIcon } from '../../assets/icons/flight-landing.svg';
export { ReactComponent as FlightTakeOffIcon } from '../../assets/icons/flight-take-off.svg';
export { ReactComponent as GroupFillIcon } from '../../assets/icons/group-fill.svg';
export { ReactComponent as HistoryFillIcon } from '../../assets/icons/history-fill.svg';
export { ReactComponent as Home5LineIcon } from '../../assets/icons/home-5-line.svg';
export { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
export { ReactComponent as LogoSkyBookingIcon } from '../../assets/icons/logo-sky-booking.svg';
export { ReactComponent as LogoTripBookingIcon } from '../../assets/icons/logo-trip-booking.svg';
export { ReactComponent as MapPin2FillIcon } from '../../assets/icons/map-pin-2-fill.svg';
export { ReactComponent as MenuLineIcon } from '../../assets/icons/menu-line.svg';
export { ReactComponent as More2FillIcon } from '../../assets/icons/more-2-fill.svg';
export { ReactComponent as Notification3FillIcon } from '../../assets/icons/notification-3-fill.svg';
export { ReactComponent as PeopleFillIcon } from '../../assets/icons/people-fill.svg';
export { ReactComponent as PlaneFillIcon } from '../../assets/icons/plane-fill.svg';
export { ReactComponent as PlaneIcon } from '../../assets/icons/plane.svg';
export { ReactComponent as PlusLineIcon } from '../../assets/icons/plus-line.svg';
export { ReactComponent as ProfileFillIcon } from '../../assets/icons/profile-fill.svg';
export { ReactComponent as Refund2LineIcon } from '../../assets/icons/refund-2-line.svg';
export { ReactComponent as RightArrowIcon } from '../../assets/icons/right-arrow-icon.svg';
export { ReactComponent as RingBellServiceIcon } from '../../assets/icons/ring-service.svg';
export { ReactComponent as SeatClassIcon } from '../../assets/icons/seat-class.svg';
export { ReactComponent as SeatIcon } from '../../assets/icons/seat.svg';
export { ReactComponent as SortDesc1to9Icon } from '../../assets/icons/sort-desc-1-9.svg';
export { ReactComponent as SortDescIcon } from '../../assets/icons/sort-desc.svg';
export { ReactComponent as SubLineIcon } from '../../assets/icons/sub-line.svg';
export { ReactComponent as SuitcaseFillIcon } from '../../assets/icons/suitcase-fill.svg';
export { ReactComponent as SwapFlightIcon } from '../../assets/icons/swap-flight.svg';
export { ReactComponent as Ticket2FillIcon } from '../../assets/icons/ticket-2-fill.svg';
export { ReactComponent as TimeFillIcon } from '../../assets/icons/time-fill.svg';
export { ReactComponent as MoneyDollarCircleLineIcon } from '../../assets/icons/money-dollar-circle-line.svg';

// Trong
export { ReactComponent as AddCircleIcon } from '../../assets/icons/add-circle-line.svg';
export { ReactComponent as AirPlane3FillIcon } from '../../assets/icons/air-plane-3-fill.svg';
export { ReactComponent as BookingIcon } from '../../assets/icons/booking.svg';
export { ReactComponent as CheckFillIcon } from '../../assets/icons/check-fill-icon.svg';
export { ReactComponent as CheckLineIcon } from '../../assets/icons/check-line.svg';
export { ReactComponent as ChooseAgainIcon } from '../../assets/icons/choose-again.svg';
export { ReactComponent as EyeOffLineIcon } from '../../assets/icons/eye-off-line.svg';
export { ReactComponent as EyeOnLineIcon } from '../../assets/icons/eye-on-line.svg';
export { ReactComponent as GroupLineIcon } from '../../assets/icons/group-line.svg';
export { ReactComponent as InformationFillIcon } from '../../assets/icons/information-fill.svg';
export { ReactComponent as InformationLineIcon } from '../../assets/icons/information-line.svg';
export { ReactComponent as ListSettingsFillIcon } from '../../assets/icons/list-settings-fill.svg';
export { ReactComponent as MailLineIcon } from '../../assets/icons/mail-line.svg';
export { ReactComponent as MoneyDollarCircleFillIcon } from '../../assets/icons/money-dollar-circle-fill.svg';
export { ReactComponent as OrdersIcon } from '../../assets/icons/orders.svg';
export { ReactComponent as PrintersFillIcon } from '../../assets/icons/printers-fill.svg';
export { ReactComponent as SearchLineIcon } from '../../assets/icons/search-line.svg';
export { ReactComponent as SortAscIcon } from '../../assets/icons/sort-asc.svg';
export { ReactComponent as TicketIcon } from '../../assets/icons/ticket.svg';
export { ReactComponent as LockPasswordLineIcon } from '../../assets/icons/lock-password-line.svg';
export { ReactComponent as ClipBoardLineIcon } from '../../assets/icons/clipboard-line.svg';
export { ReactComponent as UserLineIcon } from '../../assets/icons/user-line.svg';
export { ReactComponent as ArrowRightLineIcon } from '../../assets/icons/arrow-right-line-icon.svg';
export { ReactComponent as OccupiedSeatIcon } from '../../assets/icons/occupied-seat.svg';
export { ReactComponent as Wallet2LineIcon } from '../../assets/icons/wallet-2-line.svg';
export { ReactComponent as Loader2LineIcon } from '../../assets/icons/loader-2-line.svg';
export { ReactComponent as Notification2FillIcon } from '../../assets/icons/notification-2-fill.svg';
export { ReactComponent as Website8Icon } from '../../assets/icons/icons8-website.svg';
export { ReactComponent as Phone8Icon } from '../../assets/icons/icons8-phone.svg';

// Duy
export { ReactComponent as AccountCircleLineIcon } from '../../assets/icons/account-circle-line.svg';
export { ReactComponent as ArrowLeftSLineIcon } from '../../assets/icons/arrow-left-s-line.svg';
export { ReactComponent as ArrowRightSLineIcon } from '../../assets/icons/arrow-right-s-line.svg';
export { ReactComponent as ArticleLineIcon } from '../../assets/icons/article-line.svg';
export { ReactComponent as BarChartFillIcon } from '../../assets/icons/bar-chart-fill.svg';
export { ReactComponent as Calendar2FillIcon } from '../../assets/icons/calendar-2-fill.svg';
export { ReactComponent as CalendarCheckFillIcon } from '../../assets/icons/calendar-check-fill.svg';
export { ReactComponent as ClipBoardFillIcon } from '../../assets/icons/clipboard-fill.svg';
export { ReactComponent as CloseCircleIcon } from '../../assets/icons/close-circle-line.svg';
export { ReactComponent as ContactsBook2FillIcon } from '../../assets/icons/contact-book-2-fill.svg';
export { ReactComponent as Edit2FillIcon } from '../../assets/icons/edit-2-fill.svg';
export { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';
export { ReactComponent as ForbidFillIcon } from '../../assets/icons/forbid-fill.svg';
export { ReactComponent as HandbagLineIcon } from '../../assets/icons/handbag-line.svg';
export { ReactComponent as LockPasswordFillIcon } from '../../assets/icons/lock-password-fill.svg';
export { ReactComponent as LogoutBoxRLineIcon } from '../../assets/icons/logout-box-r-line.svg';
export { ReactComponent as PencilFillIcon } from '../../assets/icons/pencil-fill.svg';
export { ReactComponent as SendPlaneFillIcon } from '../../assets/icons/send-plane-fill.svg';
export { ReactComponent as Spam2LineIcon } from '../../assets/icons/spam-2-line.svg';
export { ReactComponent as StarSFillIcon } from '../../assets/icons/star-s-fill.svg';
export { ReactComponent as StatusInProcessIcon } from '../../assets/icons/status-in-process.svg';
export { ReactComponent as StatusNewIcon } from '../../assets/icons/status-new.svg';
export { ReactComponent as TimeLineIcon } from '../../assets/icons/time-line.svg';
export { ReactComponent as User2FillIcon } from '../../assets/icons/user-2-fill.svg';
export { ReactComponent as User3FillIcon } from '../../assets/icons/user-3-fill.svg';
export { ReactComponent as UserAddFillIcon } from '../../assets/icons/user-add-fill.svg';
export { ReactComponent as Wechat2LineIcon } from '../../assets/icons/wechat-2-line.svg';
export { ReactComponent as CrossIcon } from '../../assets/icons/cross.svg';
export { ReactComponent as RecycleBinIcon } from '../../assets/icons/recycle-bin.svg';
export { ReactComponent as TopUpIcon } from '../../assets/icons/topup.svg';
export { ReactComponent as FlightFillIcon } from '../../assets/icons/flight-fill.svg';
export { ReactComponent as FlightAroundIcon } from '../../assets/icons/flight-around.svg';
export { ReactComponent as HotelBigIcon } from '../../assets/icons/hotel-big.svg';
export { ReactComponent as SimIcon } from '../../assets/icons/sim.svg';
export { ReactComponent as TourBigIcon } from '../../assets/icons/tour-big.svg';
export { ReactComponent as VisaIcon } from '../../assets/icons/visa.svg';
export { ReactComponent as TicketBigIcon } from '../../assets/icons/ticket-big.svg';
export { ReactComponent as CarTourIcon } from '../../assets/icons/car-tour.svg';
export { ReactComponent as SmartCardIcon } from '../../assets/icons/smart-card.svg';
export { ReactComponent as SafeIcon } from '../../assets/icons/safe.svg';
export { ReactComponent as RoyaltyIcon } from '../../assets/icons/royalty.svg';
export { ReactComponent as HotelIcon } from '../../assets/icons/hotel.svg';
export { ReactComponent as UploadLineIcon } from '../../assets/icons/upload-line.svg';
export { ReactComponent as TicketTourIcon } from '../../assets/icons/ticket-tour.svg';

// Thieu
export { ReactComponent as AddCircleFillIcon } from '../../assets/icons/add-circle-fill.svg';
export { ReactComponent as StatusInformationIconIcon } from '../../assets/icons/status-information.svg';
export { ReactComponent as CheckIconBlue } from '../../assets/icons/icons8-check-blue.svg';
export { ReactComponent as CheckIconGrey } from '../../assets/icons/icons8-check-grey.svg';

// Khang
export { ReactComponent as Attachment } from '../../assets/icons/attachment-svgrepo-com.svg';
export { ReactComponent as Email } from '../../assets/icons/email-9-svgrepo-com.svg';
export { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left.svg';
export { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right.svg';
export { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg';
export { ReactComponent as IconLink } from '../../assets/icons/icon-link.svg';
export { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg';
export { ReactComponent as IconLocation } from '../../assets/icons/location.svg';
export { ReactComponent as IconHaftStar } from '../../assets/icons/half-star-icon.svg';
export { ReactComponent as IconFullStar } from '../../assets/icons/full-star-icon.svg';

export { ReactComponent as SquareMeterIcon } from '../../assets/icons/square-meter.svg';
export { ReactComponent as CustomerIcon } from '../../assets/icons/customer.svg';
export { ReactComponent as BedIcon } from '../../assets/icons/bed.svg';
export { ReactComponent as CircleMoneyIcon } from '../../assets/icons/circle-money.svg';

export { ReactComponent as NextIcon } from '../../assets/icons/next.svg';
export { ReactComponent as PrevIcon } from '../../assets/icons/prev.svg';
export { ReactComponent as BreakfastIcon } from '../../assets/icons/breakfast.svg';
export { ReactComponent as LocationIcon } from '../../assets/icons/location.svg';
export { ReactComponent as CheckIconCustome } from '../../assets/icons/check-icon-custome.svg';
export { ReactComponent as DownNewIcon } from '../../assets/icons/down-new.svg';
export { ReactComponent as TickIcon } from '../../assets/icons/tick.svg';
export { ReactComponent as ViewQuote } from '../../assets/icons/view-quote.svg';

//Khoa

export { ReactComponent as HotelBookingIcon } from '../../assets/icons/hotel-booking.svg';

// Khoi
export { ReactComponent as EditIconBookingHotel } from '../../assets/icons/icon-edit.svg';
export { ReactComponent as TrashHubIcon } from '../../assets/icons/trash-hub.svg';
export { ReactComponent as EditHubIcon } from '../../assets/icons/edit-bizi-hub.svg';
export { ReactComponent as EarthHubIcon } from '../../assets/icons/earth-hub.svg';
export { ReactComponent as CopyHubIcon } from '../../assets/icons/copy-hub.svg';
export { ReactComponent as AddHubIcon } from '../../assets/icons/add-bizi-hub.svg';
export { ReactComponent as UserSquareIcon } from '../../assets/icons/agent-info.svg';
export { ReactComponent as SubAgentIcon } from '../../assets/icons/sub-agent.svg';