import { Card, LoadingRing, SeatIcon } from '@common-ui';
import { IGetSeatMapReq } from '@tixlabs/grpc-client/web-partner';
import {
  ChooseSeatInfoBySegment,
  ModalChooseSeat,
} from '@web-booker/components/Booking';
import { useBooking, useChooseSeat } from '@web-booker/hooks/apps';
import { useAppDispatch } from '@web-booker/stores';
import { updatePassengersListByForm } from '@web-booker/stores/reducers/booking';
import { IFormBooking } from '@web-booker/types/ui/booking';
import { TModalChooseSeat } from '@web-booker/types/ui/seat-map';
import { defaultModalChooseSeat, getItineraryId } from '@web-booker/utils';

import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

type Props = {
  //
};

const ChooseSeatCtn = (props: Props) => {
  const dispatch = useAppDispatch();

  const { isLoading, handleFetchAllSeatMap, handleFetchAllSeatMapDomestic } =
    useChooseSeat();

  const {
    flightDataList,
    flightDataDomesticList,
    keyDomestic,
    flightIdDomestic,
    key,
    flightId,
    isOnlyDomesticBooking,
  } = useBooking();

  const methods = useFormContext<IFormBooking>();

  const passengersListValue = useWatch({ name: 'passengersList' });

  const [modalInfo, setModalInfo] = useState<TModalChooseSeat>(
    defaultModalChooseSeat
  );

  const closeModalAndConfirm = () => {
    const updatedValue = passengersListValue.map((pax, index) => {
      return {
        ...pax,
        seatOptionsList: modalInfo.passengersList[index].seatOptionsList,
      };
    });
    methods.setValue('passengersList', updatedValue);
    dispatch(updatePassengersListByForm(updatedValue));
    setModalInfo(defaultModalChooseSeat);
  };

  const closeModalWithoutConfirm = () => {
    setModalInfo(defaultModalChooseSeat);
  };

  const openModalChooseSeat = ({
    getSeatMapInfo,
    passengersList,
    segmentInfo,
  }: {
    getSeatMapInfo: TModalChooseSeat['getSeatMapInfo'];
    passengersList: TModalChooseSeat['passengersList'];
    segmentInfo: TModalChooseSeat['segmentInfo'];
  }) => {
    const clonePassengerList = JSON.parse(
      JSON.stringify(passengersList)
    ) as TModalChooseSeat['passengersList'];

    setModalInfo({
      open: true,
      getSeatMapInfo,
      passengersList: clonePassengerList,
      segmentInfo,
    });
  };

  useEffect(() => {
    if (!isLoading) {
      if (isOnlyDomesticBooking) {
        handleFetchAllSeatMapDomestic();
      } else {
        handleFetchAllSeatMap();
      }
    }
  }, [flightDataList, flightDataDomesticList]);

  return (
    <Card
      isCollapse
      defaultShow
      title='Lựa chọn chỗ ngồi'
      icon={<SeatIcon />}
      classNameChildren='p-2.5 flex flex-col gap-y-5'>
      {isLoading ? (
        <div className='flex justify-center items-center py-3'>
          <LoadingRing size='sm' />
        </div>
      ) : isOnlyDomesticBooking ? (
        flightDataDomesticList.map(
          (flightDataDomesticInfo, flightDataDomesticInfoIndex) => {
            return flightDataDomesticInfo.segmentsList.map(
              (segment, segmentIndex) => {
                const isTransitDepart =
                  flightDataDomesticInfo.segmentsList[segmentIndex - 1]
                    ?.arrivalPlace === segment.departPlace;

                const isTransitArrival =
                  flightDataDomesticInfo.segmentsList[segmentIndex + 1]
                    ?.departPlace === segment.arrivalPlace;

                const getSeatMapInfo: IGetSeatMapReq = {
                  key: keyDomestic[flightDataDomesticInfoIndex],
                  flightId: flightIdDomestic[flightDataDomesticInfoIndex],
                  itineraryId:
                    flightDataDomesticInfo.classOptionsList[0].itineraryId,
                  segmentIndex: segment.index,
                  isDomestic: isOnlyDomesticBooking,
                };

                return (
                  <ChooseSeatInfoBySegment
                    key={`${flightDataDomesticInfoIndex}-${segmentIndex}`}
                    isShowItineraryIndex={flightDataList.length > 1}
                    itineraryIndex={flightDataDomesticInfoIndex}
                    getSeatMapInfo={getSeatMapInfo}
                    passengersList={passengersListValue}
                    openSeatMapModal={() =>
                      openModalChooseSeat({
                        passengersList: passengersListValue,
                        getSeatMapInfo,
                        segmentInfo: { ...segment },
                      })
                    }
                    segmentInfo={{
                      ...segment,
                      isTransitDepart,
                      isTransitArrival,
                    }}
                  />
                );
              }
            );
          }
        )
      ) : (
        flightDataList.map((itinerary, itineraryIndex) => {
          return itinerary.segmentsList.map((segment, segmentIndex) => {
            const isTransitDepart =
              itinerary.segmentsList[segmentIndex - 1]?.arrivalPlace ===
              segment.departPlace;

            const isTransitArrival =
              itinerary.segmentsList[segmentIndex + 1]?.departPlace ===
              segment.arrivalPlace;

            const getSeatMapInfo: IGetSeatMapReq = {
              key,
              flightId,
              itineraryId: getItineraryId(flightDataList, itineraryIndex),
              segmentIndex: segment.index,
              isDomestic: isOnlyDomesticBooking,
            };

            return (
              <ChooseSeatInfoBySegment
                key={`${itineraryIndex}-${segmentIndex}`}
                isShowItineraryIndex={flightDataList.length > 1}
                itineraryIndex={itineraryIndex}
                getSeatMapInfo={getSeatMapInfo}
                passengersList={passengersListValue}
                openSeatMapModal={() =>
                  openModalChooseSeat({
                    passengersList: passengersListValue,
                    getSeatMapInfo,
                    segmentInfo: { ...segment },
                  })
                }
                segmentInfo={{
                  ...segment,
                  isTransitDepart,
                  isTransitArrival,
                }}
              />
            );
          });
        })
      )}

      <ModalChooseSeat
        modalInfo={modalInfo}
        onOk={() => closeModalAndConfirm()}
        onClose={() => closeModalWithoutConfirm()}
        updateModalState={(value) => setModalInfo(value)}
      />
    </Card>
  );
};

export default ChooseSeatCtn;
