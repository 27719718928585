// source: airplane/domestic_flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var airplane_hub_pb = require('../airplane/hub_pb.js');
goog.object.extend(proto, airplane_hub_pb);
var airplane_flight_pb = require('../airplane/flight_pb.js');
goog.object.extend(proto, airplane_flight_pb);
goog.exportSymbol('proto.airplane.DomesticFlightInfo', null, global);
goog.exportSymbol('proto.airplane.FlightClassOption', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.DomesticFlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.DomesticFlightInfo.repeatedFields_, null);
};
goog.inherits(proto.airplane.DomesticFlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.DomesticFlightInfo.displayName = 'proto.airplane.DomesticFlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.FlightClassOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.FlightClassOption.repeatedFields_, null);
};
goog.inherits(proto.airplane.FlightClassOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.FlightClassOption.displayName = 'proto.airplane.FlightClassOption';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.DomesticFlightInfo.repeatedFields_ = [13,14,16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.DomesticFlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.DomesticFlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.DomesticFlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.DomesticFlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryKey: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stopNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 5, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 6, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 7, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 8, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 9, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 10, ""),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    airplane_flight_pb.Segment.toObject, includeInstance),
    classOptionsList: jspb.Message.toObjectList(msg.getClassOptionsList(),
    proto.airplane.FlightClassOption.toObject, includeInstance),
    leg: jspb.Message.getFieldWithDefault(msg, 15, 0),
    groupIdsList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.DomesticFlightInfo}
 */
proto.airplane.DomesticFlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.DomesticFlightInfo;
  return proto.airplane.DomesticFlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.DomesticFlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.DomesticFlightInfo}
 */
proto.airplane.DomesticFlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 13:
      var value = new airplane_flight_pb.Segment;
      reader.readMessage(value,airplane_flight_pb.Segment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 14:
      var value = new proto.airplane.FlightClassOption;
      reader.readMessage(value,proto.airplane.FlightClassOption.deserializeBinaryFromReader);
      msg.addClassOptions(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeg(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.addGroupIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.DomesticFlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.DomesticFlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.DomesticFlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.DomesticFlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryKey();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStopNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      airplane_flight_pb.Segment.serializeBinaryToWriter
    );
  }
  f = message.getClassOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      proto.airplane.FlightClassOption.serializeBinaryToWriter
    );
  }
  f = message.getLeg();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getGroupIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      16,
      f
    );
  }
};


/**
 * optional string itinerary_key = 1;
 * @return {string}
 */
proto.airplane.DomesticFlightInfo.prototype.getItineraryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setItineraryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 stop_number = 2;
 * @return {number}
 */
proto.airplane.DomesticFlightInfo.prototype.getStopNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setStopNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string depart_place = 3;
 * @return {string}
 */
proto.airplane.DomesticFlightInfo.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 depart_date = 4;
 * @return {number}
 */
proto.airplane.DomesticFlightInfo.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string arrival_place = 5;
 * @return {string}
 */
proto.airplane.DomesticFlightInfo.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 arrival_date = 6;
 * @return {number}
 */
proto.airplane.DomesticFlightInfo.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string carrier_marketing = 7;
 * @return {string}
 */
proto.airplane.DomesticFlightInfo.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string carrier_operator = 8;
 * @return {string}
 */
proto.airplane.DomesticFlightInfo.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string flight_number = 9;
 * @return {string}
 */
proto.airplane.DomesticFlightInfo.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string currency = 10;
 * @return {string}
 */
proto.airplane.DomesticFlightInfo.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 flight_duration = 12;
 * @return {number}
 */
proto.airplane.DomesticFlightInfo.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * repeated Segment segments = 13;
 * @return {!Array<!proto.airplane.Segment>}
 */
proto.airplane.DomesticFlightInfo.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.airplane.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.Segment, 13));
};


/**
 * @param {!Array<!proto.airplane.Segment>} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
*/
proto.airplane.DomesticFlightInfo.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.airplane.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.Segment}
 */
proto.airplane.DomesticFlightInfo.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.airplane.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * repeated FlightClassOption class_options = 14;
 * @return {!Array<!proto.airplane.FlightClassOption>}
 */
proto.airplane.DomesticFlightInfo.prototype.getClassOptionsList = function() {
  return /** @type{!Array<!proto.airplane.FlightClassOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.FlightClassOption, 14));
};


/**
 * @param {!Array<!proto.airplane.FlightClassOption>} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
*/
proto.airplane.DomesticFlightInfo.prototype.setClassOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.airplane.FlightClassOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.FlightClassOption}
 */
proto.airplane.DomesticFlightInfo.prototype.addClassOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.airplane.FlightClassOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.clearClassOptionsList = function() {
  return this.setClassOptionsList([]);
};


/**
 * optional int32 leg = 15;
 * @return {number}
 */
proto.airplane.DomesticFlightInfo.prototype.getLeg = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setLeg = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated string group_ids = 16;
 * @return {!Array<string>}
 */
proto.airplane.DomesticFlightInfo.prototype.getGroupIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.setGroupIdsList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.addGroupIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.DomesticFlightInfo} returns this
 */
proto.airplane.DomesticFlightInfo.prototype.clearGroupIdsList = function() {
  return this.setGroupIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.FlightClassOption.repeatedFields_ = [17];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.FlightClassOption.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.FlightClassOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.FlightClassOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.FlightClassOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    flightId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cabinClass: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 5, ""),
    totalFareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    totalFareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    totalPaxFareAdt: (f = msg.getTotalPaxFareAdt()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    totalPaxFareChd: (f = msg.getTotalPaxFareChd()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    totalPaxFareInf: (f = msg.getTotalPaxFareInf()) && airplane_hub_pb.PaxFare.toObject(includeInstance, f),
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    displayProfit: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    itineraryId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    freeBaggageList: jspb.Message.toObjectList(msg.getFreeBaggageList(),
    airplane_hub_pb.HubBaggage.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.FlightClassOption}
 */
proto.airplane.FlightClassOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.FlightClassOption;
  return proto.airplane.FlightClassOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.FlightClassOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.FlightClassOption}
 */
proto.airplane.FlightClassOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailability(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareAmount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareBasic(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 9:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareAdt(value);
      break;
    case 10:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareChd(value);
      break;
    case 11:
      var value = new airplane_hub_pb.PaxFare;
      reader.readMessage(value,airplane_hub_pb.PaxFare.deserializeBinaryFromReader);
      msg.setTotalPaxFareInf(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayProfit(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 17:
      var value = new airplane_hub_pb.HubBaggage;
      reader.readMessage(value,airplane_hub_pb.HubBaggage.deserializeBinaryFromReader);
      msg.addFreeBaggage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.FlightClassOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.FlightClassOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.FlightClassOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.FlightClassOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTotalFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getTotalFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getTotalPaxFareAdt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareChd();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalPaxFareInf();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      airplane_hub_pb.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getDisplayProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFreeBaggageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      airplane_hub_pb.HubBaggage.serializeBinaryToWriter
    );
  }
};


/**
 * optional string flight_id = 1;
 * @return {string}
 */
proto.airplane.FlightClassOption.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 availability = 2;
 * @return {number}
 */
proto.airplane.FlightClassOption.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string cabin_class = 3;
 * @return {string}
 */
proto.airplane.FlightClassOption.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string booking_class = 4;
 * @return {string}
 */
proto.airplane.FlightClassOption.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fare_basis = 5;
 * @return {string}
 */
proto.airplane.FlightClassOption.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double total_fare_amount = 6;
 * @return {number}
 */
proto.airplane.FlightClassOption.prototype.getTotalFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setTotalFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double total_fare_basic = 7;
 * @return {number}
 */
proto.airplane.FlightClassOption.prototype.getTotalFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setTotalFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_tax_amount = 8;
 * @return {number}
 */
proto.airplane.FlightClassOption.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional PaxFare total_pax_fare_adt = 9;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.FlightClassOption.prototype.getTotalPaxFareAdt = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 9));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.FlightClassOption} returns this
*/
proto.airplane.FlightClassOption.prototype.setTotalPaxFareAdt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.clearTotalPaxFareAdt = function() {
  return this.setTotalPaxFareAdt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.FlightClassOption.prototype.hasTotalPaxFareAdt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional PaxFare total_pax_fare_chd = 10;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.FlightClassOption.prototype.getTotalPaxFareChd = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 10));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.FlightClassOption} returns this
*/
proto.airplane.FlightClassOption.prototype.setTotalPaxFareChd = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.clearTotalPaxFareChd = function() {
  return this.setTotalPaxFareChd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.FlightClassOption.prototype.hasTotalPaxFareChd = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PaxFare total_pax_fare_inf = 11;
 * @return {?proto.airplane.PaxFare}
 */
proto.airplane.FlightClassOption.prototype.getTotalPaxFareInf = function() {
  return /** @type{?proto.airplane.PaxFare} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaxFare, 11));
};


/**
 * @param {?proto.airplane.PaxFare|undefined} value
 * @return {!proto.airplane.FlightClassOption} returns this
*/
proto.airplane.FlightClassOption.prototype.setTotalPaxFareInf = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.clearTotalPaxFareInf = function() {
  return this.setTotalPaxFareInf(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.FlightClassOption.prototype.hasTotalPaxFareInf = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional double vat = 12;
 * @return {number}
 */
proto.airplane.FlightClassOption.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double display_price = 13;
 * @return {number}
 */
proto.airplane.FlightClassOption.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double display_profit = 14;
 * @return {number}
 */
proto.airplane.FlightClassOption.prototype.getDisplayProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setDisplayProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional string itinerary_id = 15;
 * @return {string}
 */
proto.airplane.FlightClassOption.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string group_id = 16;
 * @return {string}
 */
proto.airplane.FlightClassOption.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated HubBaggage free_baggage = 17;
 * @return {!Array<!proto.airplane.HubBaggage>}
 */
proto.airplane.FlightClassOption.prototype.getFreeBaggageList = function() {
  return /** @type{!Array<!proto.airplane.HubBaggage>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.HubBaggage, 17));
};


/**
 * @param {!Array<!proto.airplane.HubBaggage>} value
 * @return {!proto.airplane.FlightClassOption} returns this
*/
proto.airplane.FlightClassOption.prototype.setFreeBaggageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.airplane.HubBaggage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.FlightClassOption.prototype.addFreeBaggage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.airplane.HubBaggage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.FlightClassOption} returns this
 */
proto.airplane.FlightClassOption.prototype.clearFreeBaggageList = function() {
  return this.setFreeBaggageList([]);
};


goog.object.extend(exports, proto.airplane);
