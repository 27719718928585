import {
  Card,
  Form,
  FormInput,
  Button,
  FormGroupRadio,
  FormCheckbox,
} from '@common-ui';
import { useAppMutation, useCurrency } from '@web-booker/hooks/internals';
import { useAppDispatch } from '@web-booker/stores';
import {
  IManualServiceFeeState,
  changeDebt,
  updateDifferenceFee,
  updateDisplayServiceFee,
  updateDomestic,
  updateInternational,
  updateIsDebt,
  updateIsDebtCa,
  updateIsLoaded,
  updateIsNoDebt,
} from '@web-booker/stores/reducers/serviceFee';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { IFeeConfig, flightApiService } from '@tixlabs/grpc-client/web-partner';
import useServiceFee from '@web-booker/hooks/apps/useServiceFee';
import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { Empty } from '@api/base/base_pb';
import { useUserStore } from '@web-booker/hooks/stores';


const isShowServiceFee =
    process.env.NX_PARTNERSHOP_SHOW_HIDDEN_FEE || '';

const isDebtCaOptions = [
  {
    label: 'Không công nợ',
    value: 'false',
  },
  {
    label: 'Công nợ',
    value: 'true',
  },
];

const ServiceFeeTitle = () => {
  return (
    <div className='flex items-center space-x-4 w-full text-sm'>
      <label className='font-semibold'>Loại CA</label>
      <div className='flex-1 flex justify-between'>
        <FormGroupRadio
          name='isDebtCaString'
          radioOptions={isDebtCaOptions}
          className='flex justify-between'
          labelProps={{ labelClassName: '!min-w-0 cursor-pointer text-sm' }}
          radioClassName='flex-none mr-4'
        />
        <div className='flex items-center space-x-1 ml-4'>
          <FormCheckbox
            name='displayServiceFee'
            label='Hiện giá gồm phí dịch vụ'
          />
        </div>
      </div>
    </div>
  );
};

const ServiceFee = ({
  type,
  formatPrice,
  data,
}: {
  type: string;
  formatPrice: (value: number) => string;
  data: IFeeConfig;
}) => {
  return (
    <div className='col-span-3 flex-wrap'>
      <div className='w-full mb-3'>
        <b>
          {type === 'domestic' ? 'Phí dịch vụ nội địa' : 'Phí dịch vụ quốc tế'}
        </b>
      </div>
      <div className='w-full flex items-stretch gap-2'>
        <FormInput
          name={`${type}.economy`}
          label='Economy'
          placeholder='Nhập phí'
          value={formatPrice(data.economy)}
        />
        <FormInput
          name={`${type}.business`}
          label='Business'
          placeholder='Nhập phí'
          value={formatPrice(data.business)}
        />
      </div>
    </div>
  );
};

export const ManualServiceFee = ({ isHidden }: { isHidden?: boolean }) => {
  const { formatPrice } = useCurrency();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { userData } = useUserStore();
  const [checkShowServiceFee, setCheckShowServiceFee] = useState<boolean>(true);

  const {
    domestic,
    international,
    differenceFee,
    isDebtCa,
    displayServiceFee,
    isLoaded,
  } = useServiceFee();

  type IServiceFeeCustom = IManualServiceFeeState & { isDebtCaString: string };

  const methods = useForm<IServiceFeeCustom>({
    defaultValues: {
      domestic: domestic || {
        economy: 39000,
        business: 59000,
      },
      international: international || {
        economy: 69000,
        business: 99000,
      },
      differenceFee: differenceFee || 0,
      isDebtCa: isDebtCa || false,
      displayServiceFee: displayServiceFee || true,
      isDebtCaString: String(isDebtCa) || 'false',
    },
    mode: 'onBlur',
  });

  const domesticValue = useWatch({
    name: 'domestic',
    control: methods.control,
  });
  const internationalValue = useWatch({
    name: 'international',
    control: methods.control,
  });
  const differenceFeeValue = useWatch({
    name: 'differenceFee',
    control: methods.control,
  });
  const isDebtCaStringValue = useWatch({
    name: 'isDebtCaString',
    control: methods.control,
  });
  const displayServiceFeeValue = useWatch({
    name: 'displayServiceFee',
    control: methods.control,
  });
  const isDebtCaValue = useWatch({
    name: 'isDebtCa',
    control: methods.control,
  });
  const parseValueToNumber = (value: number | string) => {
    return parseFloat(value.toString().replace(/,/g, ''));
  };
  const handleServiceFee = (data: IManualServiceFeeState) => {
    dispatch(updateDomestic(data.domestic));
    dispatch(updateInternational(data.international));
    dispatch(updateDifferenceFee(data.differenceFee));
    // dispatch(updateIsDebtCa(data.isDebtCa));
    dispatch(updateDisplayServiceFee(data.displayServiceFee));
  };

  useEffect(() => {
    methods.setValue('differenceFee', parseValueToNumber(differenceFeeValue));
    !differenceFeeValue && methods.setValue('differenceFee', 0);
  }, [differenceFeeValue]);
  useEffect(() => {
    methods.setValue(
      'domestic.economy',
      parseValueToNumber(domesticValue.economy)
    );
    !domesticValue?.economy && methods.setValue('domestic.economy', 0);
  }, [domesticValue?.economy]);
  useEffect(() => {
    methods.setValue(
      'domestic.business',
      parseValueToNumber(domesticValue.business)
    );
    !domesticValue?.business && methods.setValue('domestic.business', 0);
  }, [domesticValue?.business]);
  useEffect(() => {
    methods.setValue(
      'international.economy',
      parseValueToNumber(internationalValue.economy)
    );
    !internationalValue?.economy &&
      methods.setValue('international.economy', 0);
  }, [internationalValue?.economy]);
  useEffect(() => {
    methods.setValue(
      'international.business',
      parseValueToNumber(internationalValue.business)
    );
    !internationalValue?.business &&
      methods.setValue('international.business', 0);
  }, [internationalValue?.business]);
  useEffect(() => {
    methods.setValue('isDebtCa', JSON.parse(isDebtCaStringValue));
  }, [isDebtCaStringValue]);
  useEffect(() => {
    dispatch(changeDebt(isDebtCaValue));
  }, [isDebtCaValue]);
  useEffect(() => {
    methods.setValue('domestic', domestic);
  }, [domestic]);
  useEffect(() => {
    methods.setValue('international', international);
  }, [international]);

  const { mutateAsync: getDefaultServiceFee, isLoading } = useAppMutation({
    mutationKey: ['flightApiService', 'getDefaultServiceFee'],
    mutationFn: flightApiService.getDefaultServiceFee,
    onSuccess: ({ debt, noDebt }) => {
      if (checkShowServiceFee) {
        if (!isLoaded) {
          if (noDebt) {
            if (noDebt?.domestic) {
              dispatch(updateDomestic(noDebt?.domestic));
            }
            if (noDebt?.international) {
              dispatch(updateInternational(noDebt?.international));
            }
            dispatch(updateIsNoDebt(noDebt));
          }
          if (debt) {
            dispatch(updateIsDebt(debt));
          }
          dispatch(updateIsLoaded(true));
        }
      }
    },
  });

  const getDefaultServiceFeeValue = useCallback(async () => {
    const req = new Empty();
    await getDefaultServiceFee(req);
  }, []);
  useEffect(() => {
    if (!isLoaded) {
      getDefaultServiceFeeValue();
    }
  }, []);
  useEffect(() => {
    if (userData) {
      userData.partnerShopId === isShowServiceFee
        ? setCheckShowServiceFee(true)
        : setCheckShowServiceFee(false);
    }
  }, [userData]);
  useEffect(() => {
    if (!checkShowServiceFee) {
      const defaultValue = { economy: 0, business: 0 };
      dispatch(updateDomestic(defaultValue));
      dispatch(updateInternational(defaultValue));
      dispatch(updateDifferenceFee(0));
    }
  }, [checkShowServiceFee, isLoaded]);
  if (!checkShowServiceFee) {
    return null;
  }
  return (
    <div id='ac-type' className={`${checkShowServiceFee ? 'block' : 'hidden'}`}>
      <Form methods={methods} onSubmit={handleServiceFee}>
        <Card title={<ServiceFeeTitle />} classNameTitle='!block'>
          <div className='grid grid-cols-11 gap-5 p-3'>
            <ServiceFee
              type='domestic'
              formatPrice={formatPrice}
              data={domesticValue}
            />
            <ServiceFee
              type='international'
              formatPrice={formatPrice}
              data={internationalValue}
            />
            <div className='col-span-3 flex items-end'>
              <FormInput
                name='differenceFee'
                label='Chênh lệch'
                placeholder='Nhập chênh lệch'
                value={formatPrice(differenceFeeValue)}
                inputProps={{
                  disabled:
                    location.pathname === '/booking-flight/confirm'
                      ? false
                      : true,
                }}
              />
            </div>
            <div className='col-span-2 flex items-end px-3'>
              <Button type='submit' theme='secondary' className='w-full'>
                Cập nhật
              </Button>
            </div>
          </div>
        </Card>
      </Form>
    </div>
  );
};

export default ManualServiceFee;
