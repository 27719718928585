import { Button, Form, FormInput, Phone8Icon, Website8Icon } from '@common-ui';
import { getApiErrorMessages, validatePasswordByCase } from '@core/utils';
import { ILoginForm } from '@tixlabs/grpc-client/web-partner';
import Logo from '@web-booker/components/Logo';
import { useToast } from '@web-booker/hooks/internals';
import { useAppDispatch } from '@web-booker/stores';
import { userAuthenticate } from '@web-booker/stores/reducers/user';
import { useForm } from 'react-hook-form';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export const LoginCtn = () => {
  const domainAssets =
    process.env.NX_STORAGE_ASSETS || 'https://assets.deeptech.vn';

  const slideContent = [
    `${domainAssets}/apps/flighthub/login-page/slide1.jpg`,
    `${domainAssets}/apps/flighthub/login-page/slide1.jpg`,
    `${domainAssets}/apps/flighthub/login-page/slide1.jpg`,
  ];

  const toast = useToast();

  const dispatch = useAppDispatch();

  const methods = useForm<ILoginForm>({
    defaultValues: {
      agentCode: '',
      password: '',
      userName: '',
    },
  });

  const handleLogin = async (data: ILoginForm) => {
    try {
      await dispatch(userAuthenticate(data)).unwrap();
      methods.reset();
    } catch (error) {
      const [message] = getApiErrorMessages(error);
      toast.error(message);
    }
  };

  const handleClick = () => {
    window.open('https://bizihub.vn/dang-ky-dai-ly/?flighthub', '_blank');
  };

  return (
    <div className='flex max-w-full w-full max-h-full'>
      <div className='w-[0%] md:w-[48%]'>
        <div className=' bg-white flex h-screen'>
          <Swiper
            // autoplay={{ delay: 3000 }}
            loop
            className='login-swiper'
            speed={500}
            modules={[Autoplay, Pagination, Navigation]}
            // spaceBetween={30}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}>
            {slideContent.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img
                    src={item}
                    className='h-screen w-full object-cover align-middle cursor-pointer'
                    alt={'banner ' + index}
                    loading='lazy'
                    onClick={()=>handleClick()}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div
        className={`overflow-hidden w-[100%] md:w-[52%] relative z-0 flex items-center xl:items-stretch xl:pt-[140px] justify-center`}>
        <div className='relative z-20 flex flex-col gap-y-12 xl:gap-y-24 md:w-[584px] md:px-4 xl:w-[702px]'>
          <div className='flex justify-between'>
            <Logo className='h-[30px] xl:h-[50px]' isLink={false} />

            <div className='flex flex-col justify-end text-primary font-semibold'>
              <div className='flex gap-x-2 items-center justify-end'>
                <Website8Icon className='w-4 h-4' />
                <span>FlightHUB.vn</span>
              </div>
              <div className='flex gap-x-2 items-center justify-end'>
                <Phone8Icon className='w-4 h-4' />
                <span>(+84) 55 955 0595</span>
              </div>
            </div>
          </div>
          <Form
            onSubmit={handleLogin}
            methods={methods}
            className='relative z-30'>
            <div className='flex flex-col space-y-5'>
              <span className='font-bold text-primary text-xl'>Đăng nhập</span>
              <div className='flex flex-col gap-y-5'>
                <div className='flex justify-between gap-x-5'>
                  <FormInput
                    name='agentCode'
                    label='Mã đại lý'
                    placeholder='Nhập mã đại lý'
                    labelProps={{ className: 'font-semibold text-[#262626]' }}
                    rules={{
                      required: 'Nhập mã đại lý',
                    }}
                  />
                  <FormInput
                    name='userName'
                    label='Tên đăng nhập'
                    placeholder='Nhập tên đăng nhập'
                    labelProps={{ className: 'font-semibold text-[#262626]' }}
                    rules={{
                      required: 'Nhập tên đăng nhập',
                      minLength: {
                        value: 8,
                        message: 'Từ 8 đến 20 ký tự',
                      },
                      maxLength: {
                        value: 20,
                        message: 'Từ 8 đến 20 ký tự',
                      },
                      validate: {
                        specialCharacter: (v) =>
                          !v.match(/[^a-zA-Z0-9]/) ||
                          'Không chứa ký tự đặc biệt',
                      },
                    }}
                  />
                </div>
                <FormInput
                  name='password'
                  label='Mật khẩu'
                  placeholder='Nhập mật khẩu'
                  inputProps={{ type: 'password' }}
                  labelProps={{ className: 'font-semibold text-[#262626]' }}
                  rules={{
                    required: 'Nhập mật khẩu',
                    minLength: {
                      value: 8,
                      message: 'Mật khẩu phải bao gồm tối thiểu 8 kí tự',
                    },
                    validate: {
                      checkValid: (v) => {
                        const { hasNumeric, hasCharacter } =
                          validatePasswordByCase(v);
                        return (
                          (hasNumeric && hasCharacter) ||
                          'Mật khẩu phải bao gồm tối thiểu 8 kí tự, bao gồm cả chữ và số'
                        );
                      },
                    },
                  }}
                />
                <Button
                  className='w-full'
                  type='submit'
                  disabled={methods.formState.isSubmitting}>
                  Đăng nhập
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginCtn;
