import { ICAInfo } from "@tixlabs/index"
import { LabelInput } from '@common-ui';

type Props = {
    data: ICAInfo;
}

export const CaInfoView = ({data}:Props) =>{
    return(
        <div className="grid grid-cols-12">
            <div className="col-span-2">
                <LabelInput className='space-y-[5px]' label='Mã CA'>
                    <span className='font-semibold'>
                    {data.id || '-'}
                    </span>
                </LabelInput>
            </div>
            <div className="col-span-10">
                <LabelInput className='space-y-[5px]' label='Công ty'>
                    <span className='font-semibold'>
                    {data.name || '-'}
                    </span>
                </LabelInput>
            </div>
        </div>
        
    )
}

export default CaInfoView;