import { FlightServiceClient } from '@api/airplane/web/flight_grpc_web_pb';

import { BaseApiOptions } from '@core/grpc-client';

import { BaseApi } from '../base-api';
import { BookingFlightReq, BookingFlightRes, CalculationFlightFeeReq, CalculationFlightFeeRes, ContactInfo, FareCheckReq, FareCheckRes, FlightReq, GetAirportsReq, GetAirportsRes, IssueTicketReq, IssueTicketRes, ListBookingFlightReq, ListBookingFlightRes, MarkBookingAsFailedAndCancelReq, MarkBookingAsFailedAndCancelRes, Passenger, PassengerQuantity, RetrieveBookingFlightReq, RetrieveBookingFlightRes, SearchAirportsFilter, SearchAirportsReq, SearchAirportsRes, SearchFlightFilterReq, SearchFlightReq, SearchFlightRes, SortItem, SortItemType } from '@api/airplane/web/flight_pb';
import { AccessTradeRefInfo, InvoicingInformation } from '@api/airplane/flight_pb';
import { convertLocalToUTCDate, getCookie } from '@core/utils';
import { OrderByItem, PaginationReq } from '@api/base/base_pb';

class FlightApi extends BaseApi<FlightServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);

    this.serviceClient = new FlightServiceClient(this.url, null, this.optsDev);
  }

  searchAirports = ({
    filter,
    orderByList,
    pagination = {pageLimit:100, pageNumber:1},
  }: Partial<SearchAirportsReq.AsObject>): Promise<SearchAirportsRes.AsObject> => {
    const req = new SearchAirportsReq();
    const filterObj = new SearchAirportsFilter();

    const paginationObj = new PaginationReq();

    const orderByListObj = orderByList?.map((orderByList) => {
      const order = new OrderByItem();
      order.setDesc(orderByList.desc);
      order.setField(orderByList.field);
      return order;
    });

    if (filter) {
      filterObj.setSearchText(filter.searchText);
    }

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);

    req.setFilter(filterObj);
    if (orderByListObj) {
      req.setOrderByList(orderByListObj);
    }

    req.setPagination(paginationObj);

    // return this.createDummyResponse<SearchAirportsRes.AsObject>({
    //   airportsList: airportList,
    // });

    return this.grpc<
      SearchAirportsReq,
      SearchAirportsRes,
      SearchAirportsRes.AsObject
    >(this.serviceClient?.searchAirports, req, {});
  };

  searchFlight = ({
    sortsList = [],
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: SearchFlightReq.AsObject): Promise<SearchFlightRes.AsObject> => {
    const req = new SearchFlightReq();
    const filterObj = new SearchFlightFilterReq();
    const paginationObj = new PaginationReq();
    const passengerQuantityObj = new PassengerQuantity();

    if (filter) {
      filterObj.setFlightsList(
        filter.flightsList.map((f) => {
          const flightReqObj = new FlightReq();
          flightReqObj.setStartPoint(f.startPoint);
          flightReqObj.setEndPoint(f.endPoint);

          // Date.UTC()
          const currentDepartDate = new Date(f.departDate);

          const UTCDate = new Date(
            f.departDate + new Date().getTimezoneOffset() * 60 * 1000
          );
          if (currentDepartDate.getDate() !== UTCDate.getDate()) {
            currentDepartDate.setHours(0, 0, 0, 0);
            flightReqObj.setDepartDate(
              currentDepartDate.getTime() -
                new Date().getTimezoneOffset() * 60 * 1000
            );
          } else {
            flightReqObj.setDepartDate(
              // new Date(f.departDate).setHours(0, 0, 0, 0) + new Date().getTimezoneOffset() * 60 * 1000
              f.departDate
            );
          }

          return flightReqObj;
        })
      );

      if (filter.passengerQuantity) {
        passengerQuantityObj.setAdt(filter.passengerQuantity.adt);
        passengerQuantityObj.setChd(filter.passengerQuantity.chd);
        passengerQuantityObj.setInf(filter.passengerQuantity.inf);
      }

      filter.passengerQuantity &&
        filterObj.setPassengerQuantity(passengerQuantityObj);
    }

    filterObj.setCurrency('VND');

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc || false);
        sortItemObj.setType(s.type || SortItemType.SORT_ITEM_TYPE_FASTEST);
        return sortItemObj;
      })
    );

    return this.grpc<
      SearchFlightReq,
      SearchFlightRes,
      SearchFlightRes.AsObject
    >(this.serviceClient?.searchFlight, req, {});
  };

  getAirports = ({
    codesList,
  }: GetAirportsReq.AsObject): Promise<GetAirportsRes.AsObject> => {
    const req = new GetAirportsReq();
    req.setCodesList(codesList);

    return this.grpc<GetAirportsReq, GetAirportsRes, GetAirportsRes.AsObject>(
      this.serviceClient.getAirports,
      req,
      {}
    );
  };

  fareCheck = ({
    flightId,
    key,
  }: FareCheckReq.AsObject): Promise<FareCheckRes.AsObject> => {
    const req = new FareCheckReq();
    req.setFlightId(flightId);
    req.setKey(key);

    return this.grpc<FareCheckReq, FareCheckRes, FareCheckRes.AsObject>(
      this.serviceClient.fareCheck,
      req,
      {}
    );
  };

  bookingFlight = ({
    currency,
    flightId,
    isPublic,
    key,
    note,
    sessionId,
    passengersList,
    contactInfo,
    confirmPrice,
    timezone,
    invoicingInformation,
  }: BookingFlightReq.AsObject): Promise<BookingFlightRes.AsObject> => {
    const req = new BookingFlightReq();
    console.log("flightId =",flightId)
    console.log("key =",key)
    console.log("passengersList =",passengersList)
    console.log("contactInfo =",contactInfo)
    req.setCurrency(currency);
    req.setFlightId(flightId);
    req.setIsPublic(isPublic);
    req.setKey(key);
    req.setNote(note);
    req.setSessionId(sessionId);
    req.setConfirmPrice(confirmPrice);
    req.setTimezone(timezone);

    // Static expiredAt bk
    // const currentDate = new Date();
    // currentDate.setMinutes(currentDate.getMinutes() + 15);
    // const unixTimestampInMilliseconds = currentDate.getTime();
    // req.setStaticLastTicketDate(unixTimestampInMilliseconds);

    const accessTradeRefInfo = new AccessTradeRefInfo();

    if (getCookie('_aff_sid') && getCookie('_aff_network')) {
      accessTradeRefInfo.setUtmSource(getCookie('_aff_network') || '');
      accessTradeRefInfo.setClickId(getCookie('_aff_sid') || '');
      req.setAccessTradeRefInfo(accessTradeRefInfo);
    }

    const passengersListReq = passengersList.map((passenger) => {
      const passengerReq = new Passenger();
      const {
        dob,
        firstName,
        gender,
        lastName,
        type,
        expirationDate,
        nationality,
        passportNumber,
        idIssueCountry,
      } = passenger;
      passengerReq.setDob(convertLocalToUTCDate(dob ?? 0).getTime());
      passengerReq.setFirstName(firstName);
      passengerReq.setLastName(lastName);
      passengerReq.setType(type);
      passengerReq.setGender(gender);
      expirationDate && passengerReq.setExpirationDate(expirationDate);
      nationality && passengerReq.setNationality(nationality);
      passportNumber && passengerReq.setPassportNumber(passportNumber);
      idIssueCountry && passengerReq.setIdIssueCountry(idIssueCountry);
      return passengerReq;
    });
    req.setPassengersList(passengersListReq);

    const contactInfoReq = new ContactInfo();
    if (contactInfo) {
      contactInfoReq.setTitle(contactInfo.title);
      contactInfoReq.setFirstName(contactInfo.firstName);
      contactInfoReq.setLastName(contactInfo.lastName);

      contactInfoReq.setPhoneCode(contactInfo.phoneCode);
      contactInfoReq.setPhoneNumber(contactInfo.phoneNumber);
      contactInfoReq.setEmail(contactInfo.email);
      contactInfoReq.setAddress(contactInfo.address);

      contactInfoReq.setNote(contactInfo.note);
    }
    req.setContactInfo(contactInfoReq);

    if (invoicingInformation) {
      const invoicingInfo = new InvoicingInformation();
      if (invoicingInformation.companyInfo) {
        const invoicingCompany = new InvoicingInformation.Company();
        invoicingCompany.setAddress(invoicingInformation.companyInfo?.address);
        invoicingCompany.setName(invoicingInformation.companyInfo?.name);
        invoicingCompany.setTaxCode(invoicingInformation.companyInfo?.taxCode);
        invoicingInfo.setCompanyInfo(invoicingCompany);
      }
      if (invoicingInformation.receiverInfo) {
        const invoicingReceiver = new InvoicingInformation.Receiver();
        invoicingReceiver.setAddress(
          invoicingInformation.receiverInfo?.address
        );
        invoicingReceiver.setName(invoicingInformation.receiverInfo?.name);
        invoicingReceiver.setEmail(invoicingInformation.receiverInfo?.email);
        invoicingReceiver.setNote(invoicingInformation.receiverInfo?.note);
        invoicingReceiver.setPhone(invoicingInformation.receiverInfo?.phone);
        invoicingInfo.setReceiverInfo(invoicingReceiver);
      }
      req.setInvoicingInformation(invoicingInfo);
    }
    console.log("req =",req)

    return this.grpc<
      BookingFlightReq,
      BookingFlightRes,
      BookingFlightRes.AsObject
    >(this.serviceClient.bookingFlight, req, {});
  };

  calculationFlightFee = ({
    flightId,
    key,
    passengerQuantity,
  }: CalculationFlightFeeReq.AsObject): Promise<CalculationFlightFeeRes.AsObject> => {
    const req = new CalculationFlightFeeReq();
    req.setFlightId(flightId);
    req.setKey(key);
    const passengerQuantityReq = new PassengerQuantity();
    if (passengerQuantity) {
      passengerQuantityReq.setAdt(passengerQuantity.adt);
      passengerQuantityReq.setChd(passengerQuantity.chd);
      passengerQuantityReq.setInf(passengerQuantity.inf);
    }
    req.setPassengerQuantity(passengerQuantityReq);
    return this.grpc<
      CalculationFlightFeeReq,
      CalculationFlightFeeRes,
      CalculationFlightFeeRes.AsObject
    >(this.serviceClient?.calculationFlightFee, req, {});
  };

  retrieveBookingFlight = ({
    bookingCode,
  }: RetrieveBookingFlightReq.AsObject): Promise<RetrieveBookingFlightRes.AsObject> => {
    const req = new RetrieveBookingFlightReq();

    req.setBookingCode(bookingCode);

    return this.grpc<
      RetrieveBookingFlightReq,
      RetrieveBookingFlightRes,
      RetrieveBookingFlightRes.AsObject
    >(this.serviceClient.retrieveBookingFlight, req, {});
  };

  markBookingAsFailedAndCancel = ({
    bookingCode,
  }: MarkBookingAsFailedAndCancelReq.AsObject): Promise<MarkBookingAsFailedAndCancelRes.AsObject> => {
    const req = new MarkBookingAsFailedAndCancelReq();
    req.setBookingCode(bookingCode);

    return this.grpc<
      MarkBookingAsFailedAndCancelReq,
      MarkBookingAsFailedAndCancelRes,
      MarkBookingAsFailedAndCancelRes.AsObject
    >(this.serviceClient.markBookingAsFailedAndCancel, req, {
      useAnonymousToken: true,
    });
  };

  issueTicket = ({
    bookingCode,
    isConfirmChangeTime,
  }: IssueTicketReq.AsObject): Promise<IssueTicketRes.AsObject> => {
    const req = new IssueTicketReq();
    req.setBookingCode(bookingCode);
    req.setIsConfirmChangeTime(isConfirmChangeTime);

    return this.grpc<IssueTicketReq, IssueTicketRes, IssueTicketRes.AsObject>(
      this.serviceClient.issueTicket,
      req,
      { useAnonymousToken: true }
    );
  };

  listBookingFlight = ({
    sortsList = [],
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingFlightReq.AsObject): Promise<ListBookingFlightRes.AsObject> => {
    const req = new ListBookingFlightReq();
    const filterObj = new ListBookingFlightReq.Filter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');
      filterObj.setStatusesList(filter.statusesList);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);
    req.setSortsList(
      sortsList.map((s) => {
        const sortItemObj = new SortItem();
        sortItemObj.setDesc(s.desc);
        sortItemObj.setType(s.type);
        return sortItemObj;
      })
    );

    return this.grpc<
      ListBookingFlightReq,
      ListBookingFlightRes,
      ListBookingFlightRes.AsObject
    >(this.serviceClient?.listBookingFlight, req, {});
  };
}

export const flightApiService = new FlightApi();

export default flightApiService;
