import {
  IDomesticFlightInfo,
  IFlightClassOption,
  IPassengerQuantity,
} from '@tixlabs/grpc-client/web-partner';

import { Button } from '@common-ui';
import { useEffect, useMemo, useState } from 'react';
import { FlightClassesListInfoDomestic } from '@web-booker/components/Flights/FlightClassesListInfoDomestic';
import FlightItemDomestic from '@web-booker/components/Flights/FlightItemDomestic';
import { useSelectFlight } from '@web-booker/hooks/apps';
import { useAppSelector } from '@web-booker/stores';
import { EFilterDisplayMode } from '@web-booker/types/ui/flight';
import { BookingClassType, AirlineBookingCode } from '@web-booker/utils';

type Props = {
  data: IDomesticFlightInfo;
  passengerQuantity: IPassengerQuantity;
};

export function SearchFlightItemDomestic({ data, passengerQuantity }: Props) {
  const { handleSelectFlightDomestic, handleSelectFlightDomesticRebook } =
    useSelectFlight();
  const {
    bookingInfo: { flightDataDomesticList },
  } = useAppSelector((state) => state.booking);

  const {
    filter: { flightsList },
    currentSelectIndex,
    filterClient: { displayMode },
    isDomesticSearch,
    isRebook,
  } = useAppSelector((state) => state.searchFlight);
  const [activeIndex, setActiveIndex] = useState(0);
  const [bookingClassType, setBookingClassType] = useState(0)

  const sortClassList: IFlightClassOption[] = useMemo(
    () =>
      [...data.classOptionsList]
        .sort((a, b) => {
          const priceDataA = a.totalFareBasic || 0;
          const priceDataB = b.totalFareBasic || 0;

          return priceDataA - priceDataB;
        })
        .map((dataClassItem) => {
          const priceData = dataClassItem;
          let priceToShowByMode = 0;

          switch (displayMode) {
            case EFilterDisplayMode.TOTAL_PASSENGER:
              priceToShowByMode = priceData?.displayPrice || 0;
              break;
            case EFilterDisplayMode.BASIC_ADT:
              priceToShowByMode = priceData?.totalPaxFareAdt?.fareBasic || 0;
              break;
            case EFilterDisplayMode.TOTAL_PER_ADT:
              priceToShowByMode = priceData?.totalPaxFareAdt?.displayPrice || 0;
              break;
            default:
              priceToShowByMode = priceData?.displayPrice || 0;
              break;
          }
          return { ...dataClassItem, displayPrice: priceToShowByMode };
        }) || [],
    [data.classOptionsList, displayMode]
  );

  function onSelectFlight() {
    if (isRebook) {
      handleSelectFlightDomesticRebook(data, sortClassList[activeIndex]);
      return;
    }
    handleSelectFlightDomestic(data, sortClassList[activeIndex]);
  }

  function findCurrentActiveClass(selectedFlight: IDomesticFlightInfo) {
    const currentClass = selectedFlight.classOptionsList[0];
    // if (currentClass) {
    const findActiveIndex = data.classOptionsList.findIndex(
      (item) => item.flightId === data.validFlightClassOptionData?.flightId
    );
    if (findActiveIndex >= 0) {
      setActiveIndex(findActiveIndex);
    }
    // }
  }

  useEffect(() => {
    if (flightDataDomesticList[currentSelectIndex - 1]) {
      findCurrentActiveClass(flightDataDomesticList[currentSelectIndex - 1]);
    }
  }, []);
  const totalPassengers = Object.values(passengerQuantity as { [key: string]: number }).reduce((sum, value) => sum + value, 0);
  useEffect(()=>{
    setBookingClassType(
      getBookingClassType(
        data.carrierMarketing, 
        sortClassList[activeIndex].bookingClass, 
        sortClassList[activeIndex].cabinClass
    ));
  }, [activeIndex])

  function contains(array: string[], value: string): boolean {
    return array.includes(value);
  }
  function getBookingClassType(airline: string, bookingClass: string, cabinClass: string): BookingClassType {
    const value = AirlineBookingCode[airline];
    if (value) {
        if (contains(value[BookingClassType.Economy], bookingClass)) {
            return BookingClassType.Economy;
        }

        if (contains(value[BookingClassType.Business], bookingClass)) {
            return BookingClassType.Business;
        }
    }

    if (cabinClass.toLowerCase().includes("business")) {
        return BookingClassType.Business;
    }

    return BookingClassType.Economy;
  }
  return (
    <div className=''>
      <FlightItemDomestic
        flightIndex={currentSelectIndex}
        className='bg-neutral-1 px-2.5'
        classNamePanel='mb-2.5'
        data={data}
        passengerQuantity={passengerQuantity}
        activeClassIndex={activeIndex}>
        <div className='flex py-2.5 flex-nowrap items-stretch content-center space-x-1'>
          <FlightClassesListInfoDomestic
            activeIndex={activeIndex}
            className='shrink-0'
            data={sortClassList}
            changeActiveIndex={(value) => setActiveIndex(value)}
            flightQuantity={flightsList.length}
            isOnlyDomesticBooking={isDomesticSearch}
            validFlightClassOptionData={data.validFlightClassOptionData}
            totalPassengers = {totalPassengers || 0}
            bookingClassType={bookingClassType}
            carrierMarketing={data.carrierMarketing}
          />
          {/* <div className='text-neutral-10 text-center flex flex-col items-center justify-center bg-neutral-4 rounded-[3px] px-2 py-1'>
              <span className='font-bold text-[15px]'>
                {data.classesList[activeIndex].seatRemain}
              </span>
              <span className='text-[13px]'>chỗ</span>
            </div> */}

          <Button
            onClick={onSelectFlight}
            className='w-[90px] !rounded'
            variant='solid'
            theme='green'
            rounded='md'>
            <div className='flex items-center gap-1'>
              Chọn
              {/* <ArrowDownSLineIcon className='-rotate-90' /> */}
            </div>
          </Button>
        </div>
      </FlightItemDomestic>
    </div>
  );
}

export default SearchFlightItemDomestic;
