import { ArrowDownSLineIcon } from '@icon';
import { IFlightClass } from '@tixlabs/grpc-client/web-partner';
import { useCurrency } from '@web-booker/hooks/internals';
import cn from 'classnames';
import { PropsWithChildren, useState, useEffect } from 'react';
import PopoverArrow from '../Common/Popover';
import { FlightClassesListInfoItem } from './FlightClassesListInfoItem';
import { useServiceFee } from '@web-booker/hooks/apps';
import { useAppSelector } from '@web-booker/stores';

export function FlightClassesListInfo({
  changeActiveIndex,
  className,
  activeIndex,
  data,
  isOnlyDomesticBooking = false,
  flightQuantity,
  bookingClassType,
  totalPassengers,
  carrierMarketing
}: PropsWithChildren<{
  className?: string;
  data: IFlightClass[];
  activeIndex: number;
  flightQuantity: number;
  isOnlyDomesticBooking?: boolean;
  totalPassengers: number;
  bookingClassType: number;
  carrierMarketing: string;
  changeActiveIndex: (value: number) => void;
}>) {
  const { formatPrice, currentCurrency } = useCurrency();


  const {
    international,
    displayServiceFee
  } = useServiceFee();

  const {
    filterClient: { displayMode },
  } = useAppSelector((state) => state.searchFlight);
  const [totalServiceFee, setTotalServiceFee] = useState<number>(0);
  const showItem = data[activeIndex];

  const cabinClassList = showItem.cabinClass?.split(',');

  const priceData = showItem.validFlightClassData;
  useEffect(()=>{
    if(displayServiceFee && displayMode !== 'BAIC_ADT'){
      if(displayMode === 'TOTAL_PER_ADT'){
        setTotalServiceFee(bookingClassType === 0? international.economy : international.business);
      }else{
        setTotalServiceFee(bookingClassType === 0? international.economy * totalPassengers: international.business * totalPassengers);
      }
    }else {
      setTotalServiceFee(0);
    }
  },[international, displayServiceFee, bookingClassType, displayMode])
  return (
    <PopoverArrow
      panelClassName='right-0 left-[initial]'
      className={cn('text-theme-black')}
      isShowArrow={false}
      label={
        <div
          className={cn(
            'flex items-center text-xs gap-x-[5px] cursor-pointer justify-between',
            'px-2.5 py-[4.5px] w-[172px] border border-neutral-5 rounded-[3px] bg-neutral-3 hover:bg-neutral-4',
            className
          )}>
          {priceData && (
            <div className='flex flex-col items-end grow'>
              <div className='text-right text-neutral-10 line-clamp-2'>
                <span title={showItem.cabinClass}>
                  {cabinClassList.length > 1
                    ? `${cabinClassList[0]},...`
                    : cabinClassList[0]}
                </span>{' '}
                (<strong>{showItem.bookingClass}</strong>)
              </div>
              {/* hidden if domestic itinerary */}
              {!isOnlyDomesticBooking ? (
                <div className='text-xs text-neutral-10'>
                  Tổng giá <strong>{flightQuantity}</strong> chuyến
                </div>
              ) : null}
              <div className='flex items-center space-x-[5px]'>
                <span
                  className={cn('text-md font-bold text-secondary-6', {
                    '!text-sm': showItem.displayPrice > 100000000,
                  })}>
                    {
                      displayServiceFee ? 
                      formatPrice(showItem.displayPrice + (totalServiceFee * flightQuantity) )
                      :
                      formatPrice(showItem.displayPrice)
                    }
                </span>
                <span className='text-xs text-neutral-10'>
                  {currentCurrency.text}
                </span>
              </div>
            </div>
          )}
          <ArrowDownSLineIcon className='w-4 h-4 shrink-0' />
        </div>
      }>
      {({ close }) => (
        <div className='w-[368px] px-4 py-3 bg-neutral-1 rounded-lg shadow-2 space-y-[5px]'>
          {data.map((item, index) => (
            <FlightClassesListInfoItem
              key={index}
              activeIndex={activeIndex}
              onClick={() => {
                changeActiveIndex(index);
                close();
              }}
              totalPassengers={totalPassengers}
              carrierMarketing={carrierMarketing}
              data={item}
              className={cn(
                index === activeIndex ? '!bg-neutral-4' : 'bg-transparent'
              )}
            />
          ))}
        </div>
      )}
    </PopoverArrow>
  );
}
