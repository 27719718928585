import {
  TopUpRequestStatus,
  TopUpRequestInfo,
  Transaction,
} from '@api/skyhub/base_pb';
import {
  BookingDetails,
  BookingInfo,
  FlightItinerary,
} from '@api/skyhub/flight_pb';
import {
  BookingNotification,
  FileAttachment,
} from '@api/skyhub/web_partnership/notification_pb';

export interface ITransaction extends Transaction.AsObject {}

export interface ITopUpRequestInfo extends TopUpRequestInfo.AsObject {}
export type ETopUpRequestStatus = TopUpRequestStatus;
export const ETopUpRequestStatus = { ...TopUpRequestStatus } as const;

export interface IBookingDetail extends BookingDetails.AsObject {}
export interface IFlightItinerary extends FlightItinerary.AsObject {}
export interface IBookingInfo extends BookingInfo.AsObject {}
export interface IFileAttachment extends FileAttachment.AsObject {}
export interface IBookingNotification extends BookingNotification.AsObject {}
