import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFeeConfig } from '@tixlabs/grpc-client/web-partner';

export interface IManualServiceFeeState {
    domestic: IFeeConfig,
    international: IFeeConfig,
    differenceFee: number,
    isDebtCa: boolean,
    displayServiceFee: boolean
}


const initialState = {
    domestic: {
        economy: 0,
        business: 0
    },
    international: {
        economy: 0,
        business: 0
    },
    differenceFee: 0,
    isDebtCa: false,
    displayServiceFee: true,
    isNoDebt: {
        domestic: {
            economy: 0,
            business: 0
        },
        international: {
            economy: 0,
            business: 0
        },
    },
    isDebt: {
        domestic: {
            economy: 0,
            business: 0
        },
        international: {
            economy: 0,
            business: 0
        },
    },
    isLoaded: false
};

const serviceFeeSlice = createSlice({
    name: 'serviceFee',
    initialState,
    reducers: {
        updateIsLoaded: (state, action) => {
            state.isLoaded = action.payload
        },
        updateIsDebt : (state, action) => {
            state.isDebt = action.payload;
        },
        updateIsNoDebt: (state, action) => {
            state.isNoDebt = action.payload;
        },
        updateDomestic: (
            state,
            action: PayloadAction<IFeeConfig>
        ) => {
            state.domestic = action.payload;
            if(state.isDebtCa){
                state.isDebt.domestic = action.payload;
            }else {
                state.isNoDebt.domestic = action.payload;
            }
        },
        updateInternational: (
            state,
            action: PayloadAction<IFeeConfig>
        ) => {
            state.international = action.payload;
            if(state.isDebtCa){
                state.isDebt.international = action.payload;
            }else {
                state.isNoDebt.international = action.payload;
            }
        },
        updateDifferenceFee: (
            state,
            action: PayloadAction<number>
        ) => {
            state.differenceFee = action.payload;
        },
        updateIsDebtCa: (
            state,
            action
        ) => {
            state.isDebtCa = action.payload;
        },
        updateDisplayServiceFee: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.displayServiceFee = action.payload;
        },
        changeDebt: (state, action) => {
            state.isDebtCa = action.payload;
            if(action.payload){
                state.domestic = state.isDebt.domestic;
                state.international = state.isDebt.international;
            }else {
                state.domestic = state.isNoDebt.domestic;
                state.international = state.isNoDebt.international;
            }
        }
    }
})

export const {
    updateDomestic,
    updateInternational,
    updateDifferenceFee,
    updateIsDebtCa,
    updateDisplayServiceFee,
    changeDebt,
    updateIsDebt,
    updateIsNoDebt,
    updateIsLoaded
} = serviceFeeSlice.actions;
export default serviceFeeSlice.reducer;