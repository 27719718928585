import { Card, FlightTakeOffIcon, LoadingRing } from '@common-ui';
import useBooking from '@web-booker/hooks/apps/useBooking';
import { useCurrency } from '@web-booker/hooks/internals';
import { useEffect } from 'react';
import PriceTicketInfo from './components/PriceTicketInfo';
import SelectedFlightItem from './components/SelectedFlightItem';
import SelectedFlightItemDomestic from './components/SelectedFlightItemDomestic';
import SelectedFlightItemDomesticRebook from './components/SelectedFlightItemDomesticRebook';
import { EBookingStatus } from '@tixlabs/grpc-client';

type Props = {
  showInfo?: boolean;
};

export const SelectedFlightsInfoCtn = ({ showInfo }: Props) => {
  const { formatPrice, currency } = useCurrency();
  const {
    flightDataList,
    flightDataDomesticList,
    flightDataDomesticRebookList,
    isOnlyDomesticBooking,
    isRebook,
    isLoadingCaculationFlightFee,
    handleCalculateFlightFee,
    passengerQuantity,
    calculatedFlightFee,
    calculatedFlightFeeRebook,
    keyDomesticRebook,
  } = useBooking();
  
  useEffect(() => {
    if (showInfo) {
      handleCalculateFlightFee();
    }
  }, [showInfo]);

  if (!flightDataList.length && !isOnlyDomesticBooking) {
    return null;
  }

  if (!flightDataDomesticList.length && isOnlyDomesticBooking && !isRebook) {
    return null;
  }

  if (!flightDataDomesticRebookList.length && isRebook) {
    return null;
  }

  if (
    flightDataDomesticRebookList.length &&
    flightDataDomesticRebookList.every(
      (i) => i.status === undefined || i.status === EBookingStatus.COMPLETE
    ) &&
    isRebook
  ) {
    return null;
  }
  const renderSelectedFlightList = () => {
    if (isOnlyDomesticBooking) {
      if (isRebook) {
        return flightDataDomesticRebookList.map((item, index) => {
          if (
            !item.arrivalDate ||
            item.status === EBookingStatus.COMPLETE ||
            item.status === undefined
          ) {
            return null;
          }
          return (
            <SelectedFlightItemDomesticRebook
              key={index}
              index={index}
              data={item}
              passengerQuantity={passengerQuantity}
            />
          );
        });
      }
      return flightDataDomesticList.map((item, index) => {
        return (
          <SelectedFlightItemDomestic
            key={index}
            index={index}
            data={item}
            passengerQuantity={passengerQuantity}
          />
        );
      });
    }

    return flightDataList.map((item, index) => {
      return (
        <SelectedFlightItem
          key={index}
          index={index}
          data={item}
          passengerQuantity={passengerQuantity}
        />
      );
    });
  };

  return (
    <div id='selected-flight-info'>
      <Card
        icon={<FlightTakeOffIcon />}
        title={'Chuyến bay đã chọn'}
        classNameChildren='p-2.5 flex flex-col space-y-5'>
        <div className='flex flex-col space-y-2.5'>
          {renderSelectedFlightList()}
        </div>
        {showInfo && (
          <div className='space-y-2.5'>
            {isLoadingCaculationFlightFee ? (
              <div className='flex justify-center items-center w-full py-4'>
                <LoadingRing size='md' />
              </div>
            ) : (
              <>
                <PriceTicketInfo data={calculatedFlightFee} />
                <div className='flex justify-end font-semibold items-center space-x-2'>
                  <span>Tổng giá vé:</span>
                  <span className='font-bold text-xl text-secondary'>
                    {formatPrice(calculatedFlightFee.totalPrice)}
                  </span>
                  <span>{currency}</span>
                </div>
              </>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default SelectedFlightsInfoCtn;
