import { ArrowDownSLineIcon } from '@icon';
import { IFlightClassOption } from '@tixlabs/grpc-client/web-partner';
import { useCurrency } from '@web-booker/hooks/internals';
import cn from 'classnames';
import { PropsWithChildren, useEffect, useState } from 'react';
import PopoverArrow from '../Common/Popover';
import { FlightClassesListInfoItemDomestic } from './FlightClassesListInfoItemDomestic';
import { useServiceFee } from '@web-booker/hooks/apps';

export function FlightClassesListInfoDomestic({
  changeActiveIndex,
  className,
  activeIndex,
  data,
  validFlightClassOptionData,
  isOnlyDomesticBooking = false,
  flightQuantity,
  totalPassengers,
  bookingClassType,
  carrierMarketing
}: PropsWithChildren<{
  className?: string;
  data: IFlightClassOption[];
  validFlightClassOptionData?: IFlightClassOption;
  activeIndex: number;
  flightQuantity: number;
  isOnlyDomesticBooking?: boolean;
  changeActiveIndex: (value: number) => void;
  totalPassengers: number,
  bookingClassType: number,
  carrierMarketing: string
}>) {
  const { formatPrice, currentCurrency } = useCurrency();
  const {
    domestic,
    displayServiceFee
} = useServiceFee();
  const [totalServiceFee, setTotalServiceFee] = useState<number>(0);
  const showItem = data[activeIndex];

  const cabinClassList = showItem.cabinClass?.split(',');

  const priceData = validFlightClassOptionData;
  useEffect(()=>{
    if(displayServiceFee){
      setTotalServiceFee(bookingClassType === 0? domestic.economy * totalPassengers: domestic.business * totalPassengers);
    }else {
      setTotalServiceFee(0);
    }
  },[domestic, displayServiceFee, bookingClassType])
  return (
    <PopoverArrow
      panelClassName='right-0 left-[initial]'
      className={cn('text-theme-black')}
      isShowArrow={false}
      label={
        <div
          className={cn(
            'flex items-center text-xs gap-x-[5px] cursor-pointer justify-between',
            'px-2.5 py-[4.5px] w-[172px] border border-neutral-5 rounded-[3px] bg-neutral-3 hover:bg-neutral-4',
            className
          )}>
          {priceData && (
            <div className='flex flex-col items-end grow'>
              <div className='text-right text-neutral-10 line-clamp-2'>
                <span title={showItem.cabinClass}>
                  {cabinClassList.length > 1
                    ? `${cabinClassList[0]},...`
                    : cabinClassList[0]}
                </span>{' '}
                (<strong>{showItem.bookingClass}</strong>)
              </div>
              {/* hidden if domestic itinerary */}
              {!isOnlyDomesticBooking ? (
                <div className='text-xs text-neutral-10'>
                  Tổng giá <strong>{flightQuantity}</strong> chuyến
                </div>
              ) : null}
              <div className='flex items-center space-x-[5px]'>
                <span
                  className={cn('text-md font-bold text-secondary-6', {
                    '!text-sm': showItem.displayPrice > 100000000,
                  })}>
                    {
                      displayServiceFee ? 
                      formatPrice(showItem.displayPrice + totalServiceFee)
                      :
                      formatPrice(showItem.displayPrice )
                    }
                </span>
                <span className='text-xs text-neutral-10'>
                  {currentCurrency.text}
                </span>
              </div>
            </div>
          )}
          <ArrowDownSLineIcon className='w-4 h-4 shrink-0' />
        </div>
      }>
      {({ close }) => (
        <div className='w-[368px] px-4 py-3 bg-neutral-1 rounded-lg shadow-2 space-y-[5px]'>
          {data.map((item, index) => (
            <FlightClassesListInfoItemDomestic
              key={index}
              activeIndex={activeIndex}
              totalPassengers={totalPassengers}
              carrierMarketing={carrierMarketing}
              onClick={() => {
                changeActiveIndex(index);
                close();
              }}
              data={item}
              validFlightClassOptionData={validFlightClassOptionData}
              className={cn(
                index === activeIndex ? '!bg-neutral-4' : 'bg-transparent'
              )}
            />
          ))}
        </div>
      )}
    </PopoverArrow>
  );
}
