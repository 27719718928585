import { LayoutErrorBoundary, withSuspense } from '@common-ui';
import { UpdateProfile } from '@web-booker/containers';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import {
  AuthLayout,
  DashboardLayout,
  ExternalLayout,
  RootLayout,
} from '../layout';
import TestRoute from '../pages/Test/TestRoute';
import { ProcessingPayment } from '@web-booker/containers/TopUp';

const Login = withSuspense(lazy(() => import('../pages/Auth/LoginPage')));

const ForgotPassword = withSuspense(
  lazy(() => import('../pages/Auth/ForgotPasswordPage'))
);

const Booking = withSuspense(lazy(() => import('../pages/Booking')));
const FlightList = withSuspense(
  lazy(() => import('@web-booker/containers/Booking/FlightList'))
);
const BookingInfo = withSuspense(
  lazy(() => import('@web-booker/containers/Booking/BookingInfo/BookingInfo'))
);
const BookingConfirm = withSuspense(
  lazy(() => import('@web-booker/containers/Booking/BookingConfirm'))
);

const OrderManagementPage = withSuspense(
  lazy(() => import('../pages/OrderManagement'))
);
const TransactionManagementPage = withSuspense(
  lazy(() => import('../pages/TransactionManagement'))
);
const ListOrder = withSuspense(
  lazy(() => import('@web-booker/containers/OrderManagement/ListOrder'))
);
const ListTransaction = withSuspense(
  lazy(
    () => import('@web-booker/containers/TransactionManagement/ListTransaction')
  )
);
const DetailOrder = withSuspense(
  lazy(
    () =>
      import('@web-booker/containers/OrderManagement/DetailOrder/DetailOrder')
  )
);
const ExportedTicket = withSuspense(
  lazy(() => import('@web-booker/containers/OrderManagement/ExportedTicket'))
);

const PrintTicket = withSuspense(
  lazy(() => import('@web-booker/containers/OrderManagement/PrintTicket'))
);

const PageNotFound = withSuspense(
  lazy(() => import('../pages/NotFound/NotFound'))
);
const Profile = withSuspense(lazy(() => import('../pages/Profile/Profile')));

const DashboardPage = withSuspense(lazy(() => import('../pages/Dashboard')));
const HotelPage = withSuspense(lazy(() => import('../pages/Hotel/Hotel')));
const HomePage = withSuspense(lazy(() => import('../pages/Home/Home')));
const TicketTourPage = withSuspense(
  lazy(() => import('../pages/TicketTour/TicketTour'))
);

export const genRoutStructure = (additionalRouteStructure: any[]) => {
  return [
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {
          hasErrorBoundary: true,
          ErrorBoundary: LayoutErrorBoundary,
          path: 'auth',
          element: <AuthLayout />,
          children: [
            {
              index: true,
              element: <Navigate to='/auth/login' replace />,
            },
            {
              path: 'login',
              element: <Login />,
            },
          ],
        },
        ...additionalRouteStructure,
      ],
    },
    {
      hasErrorBoundary: true,
      ErrorBoundary: LayoutErrorBoundary,
      element: <ExternalLayout />,
      children: [
        {
          path: 'print-ticket/:bookingCode',
          element: <PrintTicket />,
        },
      ],
    },
    { path: '*', element: <PageNotFound /> },
  ];
};

// export const routeStructure: RouteObject[] = [
//   {
//     path: '/',
//     element: <RootLayout />,
//     hasErrorBoundary: true,
//     ErrorBoundary: LayoutErrorBoundary,
//     children: [
//       {
//         element: <DashboardLayout />,
//         children: [
//           {
//             index: true,
//             element: <Navigate to='home' replace />,
//           },
//           {
//             path: 'hotel',
//             element: <HotelPage />,
//           },
//           {
//             path: 'home',
//             element: <HomePage />,
//           },
//           {
//             path: 'dashboard',
//             element: <DashboardPage />,
//           },
//           {
//             path: 'booking-flight/ticket/:bookingCode',
//             element: <ExportedTicket />,
//           },
//           {
//             path: 'top-up',
//             element: <ProcessingPayment />,
//           },
//           {
//             path: 'booking-flight',
//             element: <Booking />,
//             children: [
//               {
//                 index: true,
//                 element: <Navigate to='search' replace />,
//               },
//               {
//                 path: 'search',
//                 element: <FlightList />,
//               },
//               {
//                 path: 'information',
//                 element: <BookingInfo />,
//               },
//               {
//                 path: 'confirm',
//                 element: <BookingConfirm />,
//               },
//             ],
//           },
//           {
//             path: 'order-management',
//             element: <OrderManagementPage />,
//             children: [
//               {
//                 index: true,
//                 element: <ListOrder />,
//               },
//               {
//                 path: ':bookingCode',
//                 element: <DetailOrder />,
//               },
//             ],
//           },
//           {
//             path: 'transaction-management',
//             element: <TransactionManagementPage />,
//             children: [
//               {
//                 index: true,
//                 element: <ListTransaction />,
//               },
//             ],
//           },
//           {
//             path: 'profile',
//             element: <Profile />,
//             children: [
//               {
//                 index: true,
//                 element: <UpdateProfile />,
//               },
//             ],
//           },
//           {
//             path: 'test-page-1704765988018',
//             element: <TestRoute />,
//           },
//         ],
//       },
//       {
//         path: 'auth',
//         element: <AuthLayout />,
//         children: [
//           {
//             index: true,
//             element: <Navigate to='/auth/login' replace />,
//           },
//           {
//             path: 'login',
//             element: <Login />,
//           },
//           {
//             path: 'forgot-password',
//             element: <ForgotPassword />,
//           },
//         ],
//       },
//       { path: '*', element: <PageNotFound /> },
//     ],
//   },
//   {
//     hasErrorBoundary: true,
//     ErrorBoundary: LayoutErrorBoundary,
//     element: <ExternalLayout />,
//     children: [
//       {
//         path: 'print-ticket/:bookingCode',
//         element: <PrintTicket />,
//       },
//     ],
//   },
//   { path: '*', element: <PageNotFound /> },
// ];

export const fullRouteStructureDashBoard = {
  // path: '/dashboard',
  element: <DashboardLayout />,
  hasErrorBoundary: true,
  ErrorBoundary: LayoutErrorBoundary,
  children: [
    // {
    //   path: 'home',
    //   element: <HomePage />,
    // },
    // {
    //   path: 'hotel',
    //   element: <HotelPage />,
    // },
    // {
    //   path: 'ticket-tour',
    //   element: <TicketTourPage />,
    // },
    {
      path: 'booking-flight',
      element: <Booking />,
      children: [
        {
          index: true,
          element: <Navigate to='search' replace />,
        },
        {
          path: 'search',
          element: <FlightList />,
        },
        {
          path: 'information',
          element: <BookingInfo />,
        },
        {
          path: 'confirm',
          element: <BookingConfirm />,
        },
      ],
    },
    {
      path: 'dashboard',
      element: <DashboardPage />,
    },
    {
      path: 'booking-flight/ticket/:bookingCode',
      element: <ExportedTicket />,
    },
    {
      path: 'top-up',
      element: <ProcessingPayment />,
    },
    {
      path: 'order-management',
      element: <OrderManagementPage />,
      children: [
        {
          index: true,
          element: <ListOrder />,
        },
        {
          path: ':bookingCode',
          element: <DetailOrder />,
        },
      ],
    },
    {
      path: 'transaction-management',
      element: <TransactionManagementPage />,
      children: [
        {
          index: true,
          element: <ListTransaction />,
        },
      ],
    },
    {
      path: 'profile',
      element: <Profile />,
      children: [
        {
          index: true,
          element: <UpdateProfile />,
        },
      ],
    },
    {
      path: 'test-page-1704765988018',
      element: <TestRoute />,
    },
  ],
};
