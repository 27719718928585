import { Card, FormCACode, GroupFillIcon } from "@common-ui";
import { IListCAItem } from "@tixlabs/index";

type Props = {
    listCAItem: IListCAItem[]
};

const ChooseCA = ({listCAItem}:Props) => {
    return(
        <Card
            title='Thông tin CA'
            icon={<GroupFillIcon />}
            classNameChildren='px-3 py-2.5 flex flex-col space-y-5'
        >
            <div className=''>
                <FormCACode 
                    name="caInfo.id"
                    listCAItem={listCAItem}
                />
            </div>
        </Card>
    )
}
export default ChooseCA;