import { useAppDispatch, useAppSelector } from "@web-booker/stores"

export const useServiceFee = () => {
    const dispatch = useAppDispatch();

    const {
        domestic,
        international,
        differenceFee,
        isDebtCa,
        displayServiceFee,
        isLoaded
    } = useAppSelector((state)=>state.serviceFee);

    return {
        domestic,
        international,
        differenceFee,
        isDebtCa,
        displayServiceFee,
        isLoaded
    }
}

export default useServiceFee;