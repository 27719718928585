// source: airplane/hub.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var airplane_base_pb = require('../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var airplane_transaction_pb = require('../airplane/transaction_pb.js');
goog.object.extend(proto, airplane_transaction_pb);
goog.exportSymbol('proto.airplane.Airline', null, global);
goog.exportSymbol('proto.airplane.BookingClassDetail', null, global);
goog.exportSymbol('proto.airplane.FareData', null, global);
goog.exportSymbol('proto.airplane.HubBaggage', null, global);
goog.exportSymbol('proto.airplane.HubFlightInfo', null, global);
goog.exportSymbol('proto.airplane.HubItinerary', null, global);
goog.exportSymbol('proto.airplane.HubPaymentMethod', null, global);
goog.exportSymbol('proto.airplane.HubRouting', null, global);
goog.exportSymbol('proto.airplane.HubSegment', null, global);
goog.exportSymbol('proto.airplane.HubTransaction', null, global);
goog.exportSymbol('proto.airplane.PaxFare', null, global);
goog.exportSymbol('proto.airplane.PaymentInfo', null, global);
goog.exportSymbol('proto.airplane.PaymentInfo.PaymentMethod', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubRouting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubRouting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubRouting.displayName = 'proto.airplane.HubRouting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubFlightInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubFlightInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubFlightInfo.displayName = 'proto.airplane.HubFlightInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubSegment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubSegment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubSegment.displayName = 'proto.airplane.HubSegment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubBaggage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubBaggage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubBaggage.displayName = 'proto.airplane.HubBaggage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.PaxFare = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.PaxFare, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.PaxFare.displayName = 'proto.airplane.PaxFare';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.FareData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.FareData.repeatedFields_, null);
};
goog.inherits(proto.airplane.FareData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.FareData.displayName = 'proto.airplane.FareData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubItinerary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.HubItinerary.repeatedFields_, null);
};
goog.inherits(proto.airplane.HubItinerary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubItinerary.displayName = 'proto.airplane.HubItinerary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.BookingClassDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.BookingClassDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.BookingClassDetail.displayName = 'proto.airplane.BookingClassDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.Airline = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.Airline.repeatedFields_, null);
};
goog.inherits(proto.airplane.Airline, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.Airline.displayName = 'proto.airplane.Airline';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.PaymentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.PaymentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.PaymentInfo.displayName = 'proto.airplane.PaymentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.PaymentInfo.PaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.PaymentInfo.PaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.PaymentInfo.PaymentMethod.displayName = 'proto.airplane.PaymentInfo.PaymentMethod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubTransaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubTransaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubTransaction.displayName = 'proto.airplane.HubTransaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.HubPaymentMethod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.HubPaymentMethod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.HubPaymentMethod.displayName = 'proto.airplane.HubPaymentMethod';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubRouting.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubRouting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubRouting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubRouting.toObject = function(includeInstance, msg) {
  var f, obj = {
    departPlace: jspb.Message.getFieldWithDefault(msg, 1, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubRouting}
 */
proto.airplane.HubRouting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubRouting;
  return proto.airplane.HubRouting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubRouting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubRouting}
 */
proto.airplane.HubRouting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubRouting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubRouting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubRouting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubRouting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional string depart_place = 1;
 * @return {string}
 */
proto.airplane.HubRouting.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubRouting} returns this
 */
proto.airplane.HubRouting.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 depart_date = 2;
 * @return {number}
 */
proto.airplane.HubRouting.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubRouting} returns this
 */
proto.airplane.HubRouting.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string arrival_place = 3;
 * @return {string}
 */
proto.airplane.HubRouting.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubRouting} returns this
 */
proto.airplane.HubRouting.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 arrival_date = 4;
 * @return {number}
 */
proto.airplane.HubRouting.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubRouting} returns this
 */
proto.airplane.HubRouting.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubFlightInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubFlightInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubFlightInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubFlightInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 1, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    aircraft: jspb.Message.getFieldWithDefault(msg, 4, ""),
    departTerminal: jspb.Message.getFieldWithDefault(msg, 5, ""),
    arrivalTerminal: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubFlightInfo}
 */
proto.airplane.HubFlightInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubFlightInfo;
  return proto.airplane.HubFlightInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubFlightInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubFlightInfo}
 */
proto.airplane.HubFlightInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAircraft(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartTerminal(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalTerminal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubFlightInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubFlightInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubFlightInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubFlightInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAircraft();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDepartTerminal();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getArrivalTerminal();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string carrier_marketing = 1;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string carrier_operator = 2;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string flight_number = 3;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string aircraft = 4;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getAircraft = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setAircraft = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string depart_terminal = 5;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getDepartTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setDepartTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string arrival_terminal = 6;
 * @return {string}
 */
proto.airplane.HubFlightInfo.prototype.getArrivalTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubFlightInfo} returns this
 */
proto.airplane.HubFlightInfo.prototype.setArrivalTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubSegment.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubSegment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubSegment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubSegment.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    routing: (f = msg.getRouting()) && proto.airplane.HubRouting.toObject(includeInstance, f),
    flightInfo: (f = msg.getFlightInfo()) && proto.airplane.HubFlightInfo.toObject(includeInstance, f),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubSegment}
 */
proto.airplane.HubSegment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubSegment;
  return proto.airplane.HubSegment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubSegment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubSegment}
 */
proto.airplane.HubSegment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = new proto.airplane.HubRouting;
      reader.readMessage(value,proto.airplane.HubRouting.deserializeBinaryFromReader);
      msg.setRouting(value);
      break;
    case 3:
      var value = new proto.airplane.HubFlightInfo;
      reader.readMessage(value,proto.airplane.HubFlightInfo.deserializeBinaryFromReader);
      msg.setFlightInfo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubSegment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubSegment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubSegment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubSegment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRouting();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.HubRouting.serializeBinaryToWriter
    );
  }
  f = message.getFlightInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.HubFlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.airplane.HubSegment.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubSegment} returns this
 */
proto.airplane.HubSegment.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional HubRouting routing = 2;
 * @return {?proto.airplane.HubRouting}
 */
proto.airplane.HubSegment.prototype.getRouting = function() {
  return /** @type{?proto.airplane.HubRouting} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubRouting, 2));
};


/**
 * @param {?proto.airplane.HubRouting|undefined} value
 * @return {!proto.airplane.HubSegment} returns this
*/
proto.airplane.HubSegment.prototype.setRouting = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubSegment} returns this
 */
proto.airplane.HubSegment.prototype.clearRouting = function() {
  return this.setRouting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubSegment.prototype.hasRouting = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional HubFlightInfo flight_info = 3;
 * @return {?proto.airplane.HubFlightInfo}
 */
proto.airplane.HubSegment.prototype.getFlightInfo = function() {
  return /** @type{?proto.airplane.HubFlightInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubFlightInfo, 3));
};


/**
 * @param {?proto.airplane.HubFlightInfo|undefined} value
 * @return {!proto.airplane.HubSegment} returns this
*/
proto.airplane.HubSegment.prototype.setFlightInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubSegment} returns this
 */
proto.airplane.HubSegment.prototype.clearFlightInfo = function() {
  return this.setFlightInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubSegment.prototype.hasFlightInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 flight_duration = 4;
 * @return {number}
 */
proto.airplane.HubSegment.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubSegment} returns this
 */
proto.airplane.HubSegment.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubBaggage.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubBaggage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubBaggage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubBaggage.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isHandBaggage: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    quantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paxType: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.HubBaggage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubBaggage;
  return proto.airplane.HubBaggage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubBaggage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.HubBaggage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsHandBaggage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuantity(value);
      break;
    case 5:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setPaxType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubBaggage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubBaggage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubBaggage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubBaggage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsHandBaggage();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.airplane.HubBaggage.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.airplane.HubBaggage.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_hand_baggage = 3;
 * @return {boolean}
 */
proto.airplane.HubBaggage.prototype.getIsHandBaggage = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setIsHandBaggage = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 quantity = 4;
 * @return {number}
 */
proto.airplane.HubBaggage.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional PaxType pax_type = 5;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.HubBaggage.prototype.getPaxType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.HubBaggage} returns this
 */
proto.airplane.HubBaggage.prototype.setPaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.PaxFare.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.PaxFare.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.PaxFare} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaxFare.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    fareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    fareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    serviceFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    displayProfit: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.PaxFare}
 */
proto.airplane.PaxFare.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.PaxFare;
  return proto.airplane.PaxFare.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.PaxFare} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.PaxFare}
 */
proto.airplane.PaxFare.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setPaxType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFareAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFareBasic(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServiceFee(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.PaxFare.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.PaxFare.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.PaxFare} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaxFare.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getServiceFee();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDisplayProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional PaxType pax_type = 1;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.PaxFare.prototype.getPaxType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.PaxFare} returns this
 */
proto.airplane.PaxFare.prototype.setPaxType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double fare_amount = 2;
 * @return {number}
 */
proto.airplane.PaxFare.prototype.getFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaxFare} returns this
 */
proto.airplane.PaxFare.prototype.setFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double fare_basic = 3;
 * @return {number}
 */
proto.airplane.PaxFare.prototype.getFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaxFare} returns this
 */
proto.airplane.PaxFare.prototype.setFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double tax_amount = 4;
 * @return {number}
 */
proto.airplane.PaxFare.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaxFare} returns this
 */
proto.airplane.PaxFare.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.airplane.PaxFare.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.PaxFare} returns this
 */
proto.airplane.PaxFare.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double service_fee = 6;
 * @return {number}
 */
proto.airplane.PaxFare.prototype.getServiceFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaxFare} returns this
 */
proto.airplane.PaxFare.prototype.setServiceFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double display_price = 7;
 * @return {number}
 */
proto.airplane.PaxFare.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaxFare} returns this
 */
proto.airplane.PaxFare.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double display_profit = 8;
 * @return {number}
 */
proto.airplane.PaxFare.prototype.getDisplayProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaxFare} returns this
 */
proto.airplane.PaxFare.prototype.setDisplayProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.FareData.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.FareData.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.FareData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.FareData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.FareData.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalPaxFaresList: jspb.Message.toObjectList(msg.getTotalPaxFaresList(),
    proto.airplane.PaxFare.toObject, includeInstance),
    totalFareAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalFareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    totalTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 5, ""),
    displayPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.FareData}
 */
proto.airplane.FareData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.FareData;
  return proto.airplane.FareData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.FareData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.FareData}
 */
proto.airplane.FareData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.PaxFare;
      reader.readMessage(value,proto.airplane.PaxFare.deserializeBinaryFromReader);
      msg.addTotalPaxFares(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFareBasic(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalTaxAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDisplayPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.FareData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.FareData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.FareData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.FareData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalPaxFaresList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.PaxFare.serializeBinaryToWriter
    );
  }
  f = message.getTotalFareAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getTotalTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDisplayPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * repeated PaxFare total_pax_fares = 1;
 * @return {!Array<!proto.airplane.PaxFare>}
 */
proto.airplane.FareData.prototype.getTotalPaxFaresList = function() {
  return /** @type{!Array<!proto.airplane.PaxFare>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.PaxFare, 1));
};


/**
 * @param {!Array<!proto.airplane.PaxFare>} value
 * @return {!proto.airplane.FareData} returns this
*/
proto.airplane.FareData.prototype.setTotalPaxFaresList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.PaxFare=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.PaxFare}
 */
proto.airplane.FareData.prototype.addTotalPaxFares = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.PaxFare, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.FareData} returns this
 */
proto.airplane.FareData.prototype.clearTotalPaxFaresList = function() {
  return this.setTotalPaxFaresList([]);
};


/**
 * optional double total_fare_amount = 2;
 * @return {number}
 */
proto.airplane.FareData.prototype.getTotalFareAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FareData} returns this
 */
proto.airplane.FareData.prototype.setTotalFareAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_fare_basic = 3;
 * @return {number}
 */
proto.airplane.FareData.prototype.getTotalFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FareData} returns this
 */
proto.airplane.FareData.prototype.setTotalFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double total_tax_amount = 4;
 * @return {number}
 */
proto.airplane.FareData.prototype.getTotalTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FareData} returns this
 */
proto.airplane.FareData.prototype.setTotalTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string currency = 5;
 * @return {string}
 */
proto.airplane.FareData.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.FareData} returns this
 */
proto.airplane.FareData.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional double display_price = 6;
 * @return {number}
 */
proto.airplane.FareData.prototype.getDisplayPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.FareData} returns this
 */
proto.airplane.FareData.prototype.setDisplayPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.HubItinerary.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubItinerary.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubItinerary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubItinerary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubItinerary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    index: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stopNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 4, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 5, 0),
    routing: (f = msg.getRouting()) && proto.airplane.HubRouting.toObject(includeInstance, f),
    flightInfo: (f = msg.getFlightInfo()) && proto.airplane.HubFlightInfo.toObject(includeInstance, f),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    cabinClass: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 10, ""),
    freeBaggageList: jspb.Message.toObjectList(msg.getFreeBaggageList(),
    proto.airplane.HubBaggage.toObject, includeInstance),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.airplane.HubSegment.toObject, includeInstance),
    fareDetails: (f = msg.getFareDetails()) && proto.airplane.FareData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubItinerary}
 */
proto.airplane.HubItinerary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubItinerary;
  return proto.airplane.HubItinerary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubItinerary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubItinerary}
 */
proto.airplane.HubItinerary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailability(value);
      break;
    case 6:
      var value = new proto.airplane.HubRouting;
      reader.readMessage(value,proto.airplane.HubRouting.deserializeBinaryFromReader);
      msg.setRouting(value);
      break;
    case 7:
      var value = new proto.airplane.HubFlightInfo;
      reader.readMessage(value,proto.airplane.HubFlightInfo.deserializeBinaryFromReader);
      msg.setFlightInfo(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFlightDuration(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 11:
      var value = new proto.airplane.HubBaggage;
      reader.readMessage(value,proto.airplane.HubBaggage.deserializeBinaryFromReader);
      msg.addFreeBaggage(value);
      break;
    case 12:
      var value = new proto.airplane.HubSegment;
      reader.readMessage(value,proto.airplane.HubSegment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 13:
      var value = new proto.airplane.FareData;
      reader.readMessage(value,proto.airplane.FareData.deserializeBinaryFromReader);
      msg.setFareDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubItinerary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubItinerary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubItinerary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubItinerary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStopNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRouting();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.airplane.HubRouting.serializeBinaryToWriter
    );
  }
  f = message.getFlightInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.HubFlightInfo.serializeBinaryToWriter
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getFreeBaggageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.airplane.HubBaggage.serializeBinaryToWriter
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.airplane.HubSegment.serializeBinaryToWriter
    );
  }
  f = message.getFareDetails();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.airplane.FareData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.HubItinerary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 index = 2;
 * @return {number}
 */
proto.airplane.HubItinerary.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 stop_number = 3;
 * @return {number}
 */
proto.airplane.HubItinerary.prototype.getStopNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setStopNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string fare_basis = 4;
 * @return {string}
 */
proto.airplane.HubItinerary.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 availability = 5;
 * @return {number}
 */
proto.airplane.HubItinerary.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional HubRouting routing = 6;
 * @return {?proto.airplane.HubRouting}
 */
proto.airplane.HubItinerary.prototype.getRouting = function() {
  return /** @type{?proto.airplane.HubRouting} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubRouting, 6));
};


/**
 * @param {?proto.airplane.HubRouting|undefined} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setRouting = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearRouting = function() {
  return this.setRouting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubItinerary.prototype.hasRouting = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional HubFlightInfo flight_info = 7;
 * @return {?proto.airplane.HubFlightInfo}
 */
proto.airplane.HubItinerary.prototype.getFlightInfo = function() {
  return /** @type{?proto.airplane.HubFlightInfo} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubFlightInfo, 7));
};


/**
 * @param {?proto.airplane.HubFlightInfo|undefined} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setFlightInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearFlightInfo = function() {
  return this.setFlightInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubItinerary.prototype.hasFlightInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 flight_duration = 8;
 * @return {number}
 */
proto.airplane.HubItinerary.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string cabin_class = 9;
 * @return {string}
 */
proto.airplane.HubItinerary.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string booking_class = 10;
 * @return {string}
 */
proto.airplane.HubItinerary.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated HubBaggage free_baggage = 11;
 * @return {!Array<!proto.airplane.HubBaggage>}
 */
proto.airplane.HubItinerary.prototype.getFreeBaggageList = function() {
  return /** @type{!Array<!proto.airplane.HubBaggage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.HubBaggage, 11));
};


/**
 * @param {!Array<!proto.airplane.HubBaggage>} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setFreeBaggageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.airplane.HubBaggage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.HubItinerary.prototype.addFreeBaggage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.airplane.HubBaggage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearFreeBaggageList = function() {
  return this.setFreeBaggageList([]);
};


/**
 * repeated HubSegment segments = 12;
 * @return {!Array<!proto.airplane.HubSegment>}
 */
proto.airplane.HubItinerary.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.airplane.HubSegment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.HubSegment, 12));
};


/**
 * @param {!Array<!proto.airplane.HubSegment>} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.airplane.HubSegment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubSegment}
 */
proto.airplane.HubItinerary.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.airplane.HubSegment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * optional FareData fare_details = 13;
 * @return {?proto.airplane.FareData}
 */
proto.airplane.HubItinerary.prototype.getFareDetails = function() {
  return /** @type{?proto.airplane.FareData} */ (
    jspb.Message.getWrapperField(this, proto.airplane.FareData, 13));
};


/**
 * @param {?proto.airplane.FareData|undefined} value
 * @return {!proto.airplane.HubItinerary} returns this
*/
proto.airplane.HubItinerary.prototype.setFareDetails = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubItinerary} returns this
 */
proto.airplane.HubItinerary.prototype.clearFareDetails = function() {
  return this.setFareDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubItinerary.prototype.hasFareDetails = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.BookingClassDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.BookingClassDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.BookingClassDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BookingClassDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.BookingClassDetail}
 */
proto.airplane.BookingClassDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.BookingClassDetail;
  return proto.airplane.BookingClassDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.BookingClassDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.BookingClassDetail}
 */
proto.airplane.BookingClassDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.BookingClassDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.BookingClassDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.BookingClassDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BookingClassDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.airplane.BookingClassDetail.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingClassDetail} returns this
 */
proto.airplane.BookingClassDetail.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.airplane.BookingClassDetail.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingClassDetail} returns this
 */
proto.airplane.BookingClassDetail.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.Airline.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.Airline.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.Airline.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.Airline} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.Airline.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bookingClassesList: jspb.Message.toObjectList(msg.getBookingClassesList(),
    proto.airplane.BookingClassDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.Airline}
 */
proto.airplane.Airline.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.Airline;
  return proto.airplane.Airline.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.Airline} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.Airline}
 */
proto.airplane.Airline.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.airplane.BookingClassDetail;
      reader.readMessage(value,proto.airplane.BookingClassDetail.deserializeBinaryFromReader);
      msg.addBookingClasses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.Airline.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.Airline.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.Airline} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.Airline.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBookingClassesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.BookingClassDetail.serializeBinaryToWriter
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.airplane.Airline.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Airline} returns this
 */
proto.airplane.Airline.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.airplane.Airline.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Airline} returns this
 */
proto.airplane.Airline.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated BookingClassDetail booking_classes = 3;
 * @return {!Array<!proto.airplane.BookingClassDetail>}
 */
proto.airplane.Airline.prototype.getBookingClassesList = function() {
  return /** @type{!Array<!proto.airplane.BookingClassDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.BookingClassDetail, 3));
};


/**
 * @param {!Array<!proto.airplane.BookingClassDetail>} value
 * @return {!proto.airplane.Airline} returns this
*/
proto.airplane.Airline.prototype.setBookingClassesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.BookingClassDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BookingClassDetail}
 */
proto.airplane.Airline.prototype.addBookingClasses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.BookingClassDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.Airline} returns this
 */
proto.airplane.Airline.prototype.clearBookingClassesList = function() {
  return this.setBookingClassesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.PaymentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.PaymentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.PaymentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaymentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    totalPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    method: (f = msg.getMethod()) && proto.airplane.PaymentInfo.PaymentMethod.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.PaymentInfo}
 */
proto.airplane.PaymentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.PaymentInfo;
  return proto.airplane.PaymentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.PaymentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.PaymentInfo}
 */
proto.airplane.PaymentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFeeAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalPrice(value);
      break;
    case 3:
      var value = new proto.airplane.PaymentInfo.PaymentMethod;
      reader.readMessage(value,proto.airplane.PaymentInfo.PaymentMethod.deserializeBinaryFromReader);
      msg.setMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.PaymentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.PaymentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.PaymentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaymentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTotalPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getMethod();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.PaymentInfo.PaymentMethod.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.PaymentInfo.PaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.PaymentInfo.PaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaymentInfo.PaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.PaymentInfo.PaymentMethod}
 */
proto.airplane.PaymentInfo.PaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.PaymentInfo.PaymentMethod;
  return proto.airplane.PaymentInfo.PaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.PaymentInfo.PaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.PaymentInfo.PaymentMethod}
 */
proto.airplane.PaymentInfo.PaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.PaymentInfo.PaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.PaymentInfo.PaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.PaymentInfo.PaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string code = 1;
 * @return {string}
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.PaymentInfo.PaymentMethod} returns this
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.PaymentInfo.PaymentMethod} returns this
 */
proto.airplane.PaymentInfo.PaymentMethod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double fee_amount = 1;
 * @return {number}
 */
proto.airplane.PaymentInfo.prototype.getFeeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaymentInfo} returns this
 */
proto.airplane.PaymentInfo.prototype.setFeeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double total_price = 2;
 * @return {number}
 */
proto.airplane.PaymentInfo.prototype.getTotalPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.PaymentInfo} returns this
 */
proto.airplane.PaymentInfo.prototype.setTotalPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional PaymentMethod method = 3;
 * @return {?proto.airplane.PaymentInfo.PaymentMethod}
 */
proto.airplane.PaymentInfo.prototype.getMethod = function() {
  return /** @type{?proto.airplane.PaymentInfo.PaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.airplane.PaymentInfo.PaymentMethod, 3));
};


/**
 * @param {?proto.airplane.PaymentInfo.PaymentMethod|undefined} value
 * @return {!proto.airplane.PaymentInfo} returns this
*/
proto.airplane.PaymentInfo.prototype.setMethod = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.PaymentInfo} returns this
 */
proto.airplane.PaymentInfo.prototype.clearMethod = function() {
  return this.setMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.PaymentInfo.prototype.hasMethod = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubTransaction.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubTransaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubTransaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubTransaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    transactionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paymentMethod: (f = msg.getPaymentMethod()) && proto.airplane.HubPaymentMethod.toObject(includeInstance, f),
    agentCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    transactionId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 8, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    sender: (f = msg.getSender()) && airplane_transaction_pb.Sender.toObject(includeInstance, f),
    transactionSource: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubTransaction}
 */
proto.airplane.HubTransaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubTransaction;
  return proto.airplane.HubTransaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubTransaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubTransaction}
 */
proto.airplane.HubTransaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {!proto.airplane.TransactionType} */ (reader.readEnum());
      msg.setTransactionType(value);
      break;
    case 3:
      var value = new proto.airplane.HubPaymentMethod;
      reader.readMessage(value,proto.airplane.HubPaymentMethod.deserializeBinaryFromReader);
      msg.setPaymentMethod(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 10:
      var value = new airplane_transaction_pb.Sender;
      reader.readMessage(value,airplane_transaction_pb.Sender.deserializeBinaryFromReader);
      msg.setSender(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransactionSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubTransaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubTransaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubTransaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubTransaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTransactionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getPaymentMethod();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.HubPaymentMethod.serializeBinaryToWriter
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTransactionId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getSender();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      airplane_transaction_pb.Sender.serializeBinaryToWriter
    );
  }
  f = message.getTransactionSource();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.airplane.HubTransaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TransactionType transaction_type = 2;
 * @return {!proto.airplane.TransactionType}
 */
proto.airplane.HubTransaction.prototype.getTransactionType = function() {
  return /** @type {!proto.airplane.TransactionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.airplane.TransactionType} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setTransactionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional HubPaymentMethod payment_method = 3;
 * @return {?proto.airplane.HubPaymentMethod}
 */
proto.airplane.HubTransaction.prototype.getPaymentMethod = function() {
  return /** @type{?proto.airplane.HubPaymentMethod} */ (
    jspb.Message.getWrapperField(this, proto.airplane.HubPaymentMethod, 3));
};


/**
 * @param {?proto.airplane.HubPaymentMethod|undefined} value
 * @return {!proto.airplane.HubTransaction} returns this
*/
proto.airplane.HubTransaction.prototype.setPaymentMethod = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.clearPaymentMethod = function() {
  return this.setPaymentMethod(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubTransaction.prototype.hasPaymentMethod = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string agent_code = 4;
 * @return {string}
 */
proto.airplane.HubTransaction.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string booking_code = 5;
 * @return {string}
 */
proto.airplane.HubTransaction.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.airplane.HubTransaction.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string transaction_id = 7;
 * @return {string}
 */
proto.airplane.HubTransaction.prototype.getTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 created_at = 8;
 * @return {number}
 */
proto.airplane.HubTransaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional double amount = 9;
 * @return {number}
 */
proto.airplane.HubTransaction.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional Sender sender = 10;
 * @return {?proto.airplane.Sender}
 */
proto.airplane.HubTransaction.prototype.getSender = function() {
  return /** @type{?proto.airplane.Sender} */ (
    jspb.Message.getWrapperField(this, airplane_transaction_pb.Sender, 10));
};


/**
 * @param {?proto.airplane.Sender|undefined} value
 * @return {!proto.airplane.HubTransaction} returns this
*/
proto.airplane.HubTransaction.prototype.setSender = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.clearSender = function() {
  return this.setSender(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.HubTransaction.prototype.hasSender = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string transaction_source = 11;
 * @return {string}
 */
proto.airplane.HubTransaction.prototype.getTransactionSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubTransaction} returns this
 */
proto.airplane.HubTransaction.prototype.setTransactionSource = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.HubPaymentMethod.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.HubPaymentMethod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.HubPaymentMethod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubPaymentMethod.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    method: jspb.Message.getFieldWithDefault(msg, 3, 0),
    fee: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    displayFee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.HubPaymentMethod}
 */
proto.airplane.HubPaymentMethod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.HubPaymentMethod;
  return proto.airplane.HubPaymentMethod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.HubPaymentMethod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.HubPaymentMethod}
 */
proto.airplane.HubPaymentMethod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMethod(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.HubPaymentMethod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.HubPaymentMethod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.HubPaymentMethod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.HubPaymentMethod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFee();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getDisplayFee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.airplane.HubPaymentMethod.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubPaymentMethod} returns this
 */
proto.airplane.HubPaymentMethod.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.airplane.HubPaymentMethod.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubPaymentMethod} returns this
 */
proto.airplane.HubPaymentMethod.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 method = 3;
 * @return {number}
 */
proto.airplane.HubPaymentMethod.prototype.getMethod = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubPaymentMethod} returns this
 */
proto.airplane.HubPaymentMethod.prototype.setMethod = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double fee = 4;
 * @return {number}
 */
proto.airplane.HubPaymentMethod.prototype.getFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.HubPaymentMethod} returns this
 */
proto.airplane.HubPaymentMethod.prototype.setFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string display_fee = 5;
 * @return {string}
 */
proto.airplane.HubPaymentMethod.prototype.getDisplayFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubPaymentMethod} returns this
 */
proto.airplane.HubPaymentMethod.prototype.setDisplayFee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string icon = 6;
 * @return {string}
 */
proto.airplane.HubPaymentMethod.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.HubPaymentMethod} returns this
 */
proto.airplane.HubPaymentMethod.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


goog.object.extend(exports, proto.airplane);
