import { NavLink } from 'react-router-dom';

const LogoType = {
  BLUE: 'logo-blue',
  WHITE: 'logo-white',
} as const;

type TLogoType = typeof LogoType[keyof typeof LogoType];

type Props = {
  className?: string;
  type?: TLogoType;
  isLink?: boolean;
};

const Logo = ({ className, type = 'logo-blue', isLink = true }: Props) => {
  return (
    <div className={className}>
      {isLink ? (
        <NavLink to={'/'}>
          <img
            className='w-auto h-full'
            src={`assets/images/bizitrip-logo-h-${type}.png`}
            alt='Logo'
          />
        </NavLink>
      ) : (
        <img
          className='w-auto h-full'
          src={`assets/images/bizitrip-logo-h-${type}.png`}
          alt='Logo'
        />
      )}
    </div>
  );
};

export default Logo;
