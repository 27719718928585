import {
  AccountCircleLineIcon,
  AddCircleFillIcon,
  ArrowDownSLineIcon,
  Button,
  Collapse,
  LogoutBoxRLineIcon,
  Notification3FillIcon,
} from '@common-ui';
import { useOnClickOutside } from '@core/hooks';
import { useCurrency } from '@web-booker/hooks/internals';
import { useAppDispatch } from '@web-booker/stores';
import { logout } from '@web-booker/stores/reducers/user';
import { DEFAULT_MODAL_CONTENT } from '@web-booker/utils';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuHeaderModal, { ESwitchModal, TModalHeader } from './MenuModal';
import { IWalletBasic } from '@tixlabs/grpc-client/web-partner';
import { TopUpCnt } from '@web-booker/containers/TopUp';
import usePartnerShopInfo from '@web-booker/hooks/apps/usePartnerShopInfo';

type Props = {
  name: string;
  shopID: string;
  wallet: Omit<IWalletBasic, 'currency'> & { ticketFund: number };
};

const DEFAULT_MODAL_INFO: TModalHeader = {
  ...DEFAULT_MODAL_CONTENT,
  state: ESwitchModal.NONE,
};

export const MenuHeader = ({
  name,
  shopID,
  wallet: {
    advanceBalance,
    balance,
    depositBalance,
    guaranteeAmount,
    ticketFund,
  },
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { formatCurrency, formatPrice } = useCurrency();
  const collapseRef = useRef<HTMLDivElement>(null);
  const collapseRefWallet = useRef<HTMLDivElement>(null);
  const [isShowCollapse, setIsShowCollapse] = useState(false);
  const [isShowCollapseWallet, setIsShowCollapseWallet] = useState(false);

  const [modalInfo, setModalInfo] = useState<TModalHeader>(DEFAULT_MODAL_INFO);

  const handleLogout = () => {
    dispatch(logout());
    setIsShowCollapse(false);
    setModalInfo((pre) => ({ ...pre, open: false }));
  };

  useOnClickOutside(collapseRef, () => setIsShowCollapse(false));
  useOnClickOutside(collapseRefWallet, () => setIsShowCollapseWallet(false));

  const { partnerShopBasicInfo, handleGetPartnerShopBasicInfo } =
    usePartnerShopInfo();

  useEffect(() => {
    handleGetPartnerShopBasicInfo(shopID);
  }, []);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenTopUpModal = () => {
    handleGetPartnerShopBasicInfo(shopID);
    setIsOpenModal(true);
  };

  return (
    <div className='flex space-x-5 items-center relative h-full z-[9999]'>
      <div className='flex space-x-5 items-end '>
        <Button
          prefixIcon={
            <AddCircleFillIcon className='w-5 h-5 shrink-0  text-primary-6' />
          }
          className='w-[168px] h-[32px] !bg-white  !text-primary-6 text-sm flex justify-between py-[6px] px-[6px] items-center rounded border-0 hover:!bg-white active:!bg-theme-white-variant-1'
          onClick={() => {
            handleOpenTopUpModal();
          }}>
          Nạp tiền tài khoản
        </Button>

        <TopUpCnt
          amount={0}
          isModalOpen={isOpenModal}
          setIsModalOpen={setIsOpenModal}
          shopName={name}
          shopCode={partnerShopBasicInfo?.code || ''}
          bookingCode=''
        />
      </div>
      <div className='text-right'>
        <div className='text-[13px]'>Quỹ xuất vé </div>
        <div className='text-[15px] font-bold'>
          {formatCurrency(ticketFund)}
        </div>
      </div>

      {/* <Collapse
        ref={collapseRefWallet}
        panel={
          <div className='bg-white text-black px-4 py-3 shadow-xl border rounded z-sticky-control min-w-[300px] flex flex-col gap-3'>
            <div className='flex justify-between items-center'>
              <span>Số dư tài khoản</span>
              <span className='text-primary'>{formatPrice(balance)}</span>
            </div>
            <div className='flex justify-between items-center'>
              <span>Số tiền ký quỹ</span>
              <span className='text-primary'>
                {formatPrice(depositBalance)}
              </span>
            </div>
            <div className='flex justify-between items-center'>
              <span>Số tiền tạm ứng</span>
              <span className='text-primary'>
                {formatPrice(advanceBalance)}
              </span>
            </div>
            <div className='flex justify-between items-center'>
              <span>Số tiền bảo lãnh</span>
              <span className='text-primary'>
                {formatPrice(guaranteeAmount)}
              </span>
            </div>
          </div>
        }
        isShow={isShowCollapseWallet}
        panelClassName='absolute top-full right-0'
        className='relative h-full flex items-center'>
        <div
          className='flex space-x-2 items-center cursor-pointer'
          onClick={() => setIsShowCollapseWallet((pre) => !pre)}>
          <div className='text-right'>
            <div className='text-[13px]'>Quỹ xuất vé </div>
            <div className='text-[15px] font-bold'>
              {formatCurrency(ticketFund)}
            </div>
          </div>
          <ArrowDownSLineIcon
            className={cn('duration-300', {
              'rotate-180': isShowCollapseWallet,
            })}
          />
        </div>
      </Collapse> */}

      <div className='cursor-pointer'>
        <Notification3FillIcon className='shrink-0 w-5 h-5' />
      </div>

      <Collapse
        ref={collapseRef}
        panel={
          <div className='bg-white text-black p-4 shadow-xl border rounded z-sticky-control min-w-[300px] flex flex-col gap-4'>
            <div
              onClick={() => navigate('/profile')}
              className='flex items-center gap-2.5 py-1 cursor-pointer hover:bg-primary-2 rounded duration-150'>
              <AccountCircleLineIcon />
              <span>Thông tin tài khoản</span>
            </div>
            <div
              onClick={() =>
                setModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.CHANGE_PASSWORD,
                }))
              }
              className='flex items-center gap-2.5 py-1 cursor-pointer hover:bg-primary-2 rounded duration-150'>
              <AccountCircleLineIcon />
              <span>Đổi mật khẩu</span>
            </div>
            <div className='bg-neutral-5 h-px w-full'></div>
            <div
              onClick={() =>
                setModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.CONFIRM_LOGOUT,
                }))
              }
              className='flex items-center gap-2.5 py-1 cursor-pointer hover:bg-primary-2 rounded duration-150'>
              <LogoutBoxRLineIcon />
              <span>Đăng xuất</span>
            </div>
          </div>
        }
        isShow={isShowCollapse}
        panelClassName='absolute top-full right-0'>
        <div
          className='flex space-x-2 items-center cursor-pointer h-full'
          onClick={() => setIsShowCollapse((pre) => !pre)}>
          <div className='text-[13px]'>{name}</div>
          <ArrowDownSLineIcon
            className={cn('duration-300', {
              'rotate-180': isShowCollapse,
            })}
          />
        </div>
      </Collapse>
      <MenuHeaderModal
        modalInfo={modalInfo}
        handleUpdateModalInfo={setModalInfo}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default MenuHeader;
