import { Form, FormInput, FormPaymentMethod, ButtonLoading } from '@common-ui';
import { useForm, useWatch } from 'react-hook-form';
import {
  useAppMutation,
  useCurrency,
  useToast,
} from '@web-booker/hooks/internals';
import {
  IFeeConfig,
  IManualPaymentReceipt,
  IManualServiceFee,
  IRetrieveBookingFlightData,
  flightApiService,
} from '@tixlabs/grpc-client/web-partner';
import { useEffect, useState } from 'react';
import usePaymentTopUp from '@web-booker/hooks/apps/usePaymentTopUp';

type Props = {
  bookingFlightData: IRetrieveBookingFlightData;
  isDomestic: boolean;
  getInfoTicket: (bookingCode: string) => Promise<void>;
};

const BoxServiceFee = ({
  type,
  orderStatus,
  formatPrice,
  isDomestic,
  data,
}: {
  type: string;
  orderStatus: number;
  formatPrice: (value: number) => string;
  isDomestic: boolean;
  data: IFeeConfig;
}) => {
  return (
    <div className='col-span-4 flex-wrap'>
      <div className='w-full mb-3'>
        <b>
          {type === 'domestic' ? 'Phí dịch vụ nội địa' : 'Phí dịch vụ quốc tế'}
        </b>
      </div>
      <div className='w-full flex items-stretch gap-2'>
        <FormInput
          name={`manualServiceFee.${type}.economy`}
          label='Economy'
          value={formatPrice(data.economy)}
          placeholder='Nhập phí'
          inputProps={{
            disabled:
              orderStatus === 2 ||
              orderStatus === 3 ||
              orderStatus === 6 ||
              isDomestic,
          }}
        />
        <FormInput
          name={`manualServiceFee.${type}.business`}
          label='Business'
          value={formatPrice(data.business)}
          placeholder='Nhập phí'
          inputProps={{
            disabled:
              orderStatus === 2 ||
              orderStatus === 3 ||
              orderStatus === 6 ||
              isDomestic,
          }}
        />
      </div>
    </div>
  );
};

export const ServiceFee = ({
  bookingFlightData,
  isDomestic,
  getInfoTicket,
}: Props) => {
  const { formatPrice } = useCurrency();
  const { error, success } = useToast();
  const paymentMethodsList = [
    { value: '9PAY', label: '9Pay' },
    { value: 'BANK', label: 'Chuyển khoản ngân hàng' },
    { value: 'DEBT', label: 'Công nợ' },
  ];
  const defaultFeeConfig: IFeeConfig = {
    economy: 0,
    business: 0,
  };
  const methods = useForm<IBookingManualServiceFee>({
    defaultValues: {
      manualServiceFee: {
        domestic:
          bookingFlightData.manualServiceFee?.domestic || defaultFeeConfig,
        international:
          bookingFlightData.manualServiceFee?.international || defaultFeeConfig,
        differenceFee: bookingFlightData.manualServiceFee?.differenceFee || 0,
        isDebtCa: bookingFlightData.manualServiceFee?.isDebtCa || true,
      },
      manualPaymentReceipt: bookingFlightData?.manualPaymentReceipt || {
        paymentMethod: {
          name: '',
          code: '',
        },
        transactionCode: '',
      },
    },
  });

  const manualPaymentReceiptValue = useWatch({
    name: 'manualPaymentReceipt',
    control: methods.control,
  });
  const manualServiceFeeDomesticValue = useWatch({
    name: 'manualServiceFee.domestic',
    control: methods.control,
  });
  const manualServiceFeeInternationalValue = useWatch({
    name: 'manualServiceFee.international',
    control: methods.control,
  });
  const manualServiceFeeDifferenceFeeValue = useWatch({
    name: 'manualServiceFee.differenceFee',
    control: methods.control,
  });
  interface IBookingManualServiceFee {
    manualServiceFee: IManualServiceFee;
    manualPaymentReceipt: IManualPaymentReceipt;
  }

  const { mutateAsync: requestUpdateBookingManualServiceFee, isLoading } =
    useAppMutation({
      mutationKey: ['flightApiService', 'updateBookingManualServiceFee'],
      mutationFn: flightApiService.updateBookingManualServiceFee,
      onSuccess: ({ isSuccess, errorCode }) => {
        if (isSuccess) {
          success('Cập nhật thành công');
          if (bookingFlightData.bookingCode) {
            getInfoTicket(bookingFlightData.bookingCode);
          }
        } else {
          error(errorCode);
        }
      },
    });

  const handleOrderServiceFee = (data: IBookingManualServiceFee) => {
    requestUpdateBookingManualServiceFee({
      bookingCode: bookingFlightData.bookingCode,
      manualServiceFee: data.manualServiceFee,
      manualPaymentReceipt: data.manualPaymentReceipt,
    });
  };
  const parseValueToNumber = (value: number | string) => {
    return parseFloat(value.toString().replace(/,/g, ''));
  };
  useEffect(() => {
    methods.setValue(
      'manualServiceFee.differenceFee',
      parseValueToNumber(manualServiceFeeDifferenceFeeValue)
    );
  }, [manualServiceFeeDifferenceFeeValue]);
  useEffect(() => {
    manualServiceFeeDomesticValue &&
      methods.setValue(
        'manualServiceFee.domestic.economy',
        parseValueToNumber(manualServiceFeeDomesticValue.economy)
      );
  }, [manualServiceFeeDomesticValue?.economy]);
  useEffect(() => {
    manualServiceFeeDomesticValue &&
      methods.setValue(
        'manualServiceFee.domestic.business',
        parseValueToNumber(manualServiceFeeDomesticValue.business)
      );
  }, [manualServiceFeeDomesticValue?.business]);
  useEffect(() => {
    manualServiceFeeInternationalValue &&
      methods.setValue(
        'manualServiceFee.international.economy',
        parseValueToNumber(manualServiceFeeInternationalValue.economy)
      );
  }, [manualServiceFeeInternationalValue?.economy]);
  useEffect(() => {
    manualServiceFeeInternationalValue &&
      methods.setValue(
        'manualServiceFee.international.business',
        parseValueToNumber(manualServiceFeeInternationalValue.business)
      );
  }, [manualServiceFeeInternationalValue?.business]);
  useEffect(() => {
    if (manualPaymentReceiptValue.paymentMethod) {
      const paymentMethod = paymentMethodsList.find(
        (item) => item.value === manualPaymentReceiptValue.paymentMethod?.code
      );
      const updatedPaymentMethod = {
        ...manualPaymentReceiptValue.paymentMethod,
      };
      if (paymentMethod) updatedPaymentMethod.name = paymentMethod.label;
      methods.setValue(
        'manualPaymentReceipt.paymentMethod',
        updatedPaymentMethod
      );
    }
  }, [manualPaymentReceiptValue.paymentMethod?.code]);
  return (
    <div className='p-2.5 grid space-x-2.5 border bg-[#EAF0FF]'>
      <Form methods={methods} onSubmit={handleOrderServiceFee}>
        <div className='flex flex-col items-stretch'>
          <div className='grid grid-cols-12 gap-5 border-b pb-2 border-[#c0bfbf]'>
            <BoxServiceFee
              type='domestic'
              formatPrice={formatPrice}
              orderStatus={bookingFlightData.status}
              isDomestic={isDomestic ? false : true}
              data={manualServiceFeeDomesticValue || defaultFeeConfig}
            />
            <BoxServiceFee
              type='international'
              formatPrice={formatPrice}
              orderStatus={bookingFlightData.status}
              isDomestic={isDomestic ? true : false}
              data={manualServiceFeeInternationalValue || defaultFeeConfig}
            />
            <div className='col-span-4 flex items-end'>
              <FormInput
                name='manualServiceFee.differenceFee'
                label='Chênh lệch'
                placeholder='Nhập chênh lệch'
                value={formatPrice(manualServiceFeeDifferenceFeeValue)}
                inputProps={{
                  disabled:
                    bookingFlightData.status === 2 ||
                    bookingFlightData.status === 3 ||
                    bookingFlightData.status === 6,
                }}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 gap-5 pt-2'>
            <div className='col-span-5'>
              <FormPaymentMethod
                label='Phương thức thanh toán'
                name='manualPaymentReceipt.paymentMethod.code'
                placeholder='Chọn phương thức thanh toán'
                paymentMethodList={paymentMethodsList}
              />
            </div>
            <div className='col-span-5'>
              <FormInput
                name='manualPaymentReceipt.transactionCode'
                label='Mã giao dịch'
                placeholder='Nhập mã giao dịch'
                // inputProps={{
                //     disabled: true,
                // }}
              />
            </div>
            <div className='col-span-2 flex items-end px-3'>
              <ButtonLoading
                type='submit'
                theme='secondary'
                className='w-full'
                loading={isLoading}>
                Cập nhật
              </ButtonLoading>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ServiceFee;
